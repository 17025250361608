import React from 'react';
import { Field, FieldArray } from 'formik';

import _ from "lodash";
import { loan_offer_step_text } from "./data.js";


const StepsTextForm = ({ namePrefix }) => {
    return (
        <FieldArray name={namePrefix}>
            {({ push, remove, form }) => (
                <>
                    {_.get(form.values, namePrefix) && _.get(form.values, namePrefix).length > 0 && (
                        _.get(form.values, namePrefix).map((step, index) => {
                            const currentPrefix = `${namePrefix}[${index}]`;
                            return (
                                <div key={currentPrefix} style={{ paddingLeft: 20 }}>
                                    {
                                        step.steps && step.steps.length > 0 ?
                                        <div className="row mt-40 px-5">
                                            <label className="col-md-2">Steps:</label>
                                            <StepsTextForm namePrefix={`${currentPrefix}.steps`} />
                                        </div> : null
                                    }
                                    <div className="row mt-40 px-5">
                                        <label 
                                            className="col-md-2"
                                            htmlFor={`${currentPrefix}.text`}>
                                                Text:
                                        </label>
                                        <Field
                                            className="col-md-8"
                                            type="text"
                                            name={`${currentPrefix}.text`} />
                                    </div>
                                    <button 
                                        type="button" 
                                        onClick={() => remove(index)}>
                                            -
                                    </button>
                                    <button 
                                        type="button" 
                                        onClick={() => push(_.cloneDeep(loan_offer_step_text))}>
                                            +
                                    </button>
                                </div>
                            );   
                        })
                    )}
                </>
            )}
        </FieldArray>
    );
};

export default StepsTextForm;
