import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import _ from "lodash"; 

import DataTable from "../../components/DataTable";
import MerchantIndexAction from "./action";
import { columns } from "./columns.js";
import callApi from "../callApi";
import "./style.css";

const MerchantIndexesTableView = () => {
    const [allIndexes, setAllIndexes] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 100,
        document_id: null,
        page: 0,
        search_text: null,
        filter: 0
    });
    const [searchText, setSearchText] = useState("");

    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [index, setIndex] = useState({});

    useEffect(() => {
        getMerchantIndexesData(pagination);
    }, [pagination]);

    const getMerchantIndexesData = (paginateData) => {
        setPending(true);
        let paginationFilter = 
            paginateData && "filter" in paginateData ? paginateData.filter : 0;
        let paginationLimit =
            paginateData && "limit" in paginateData ? paginateData.limit : 100;
        let paginationSearchText =
            paginateData && "search_text" in paginateData ? paginateData.search_text : null;
        let paginationDocumentId =
            paginateData && "document_id" in paginateData ? paginateData.document_id : null;
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_indexes`;
        if (paginationFilter) {
            url = url + `?filter=${paginationFilter}`;
        }
        else if (paginationSearchText) {
            url = url + `?search_text=${paginationSearchText}`;
        }
        else if (paginationDocumentId) {
            url = url + `?limit=${paginationLimit}&document_id=${paginationDocumentId}`;
        }
        callApi(url)
            .then((json) => {
                if (json.data && json.data.merchant_indexes) {
                    setAllIndexes(json.data.merchant_indexes);
                    if (!paginationSearchText && !paginationFilter) {
                        let paginateData = _.cloneDeep(paginationData)
                        paginateData[pagination.page] = [
                            paginationDocumentId,
                            json.data.merchant_indexes[json.data.merchant_indexes.length - 1].id
                        ]
                        setPaginationData(paginateData)
                    }
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const onFilterClick = (filter) => {
        let paginationData = _.cloneDeep(pagination);
        paginationData.filter = filter;
        setPagination(paginationData);
    }

    const onNextClick = () => {
        setPagination({
            limit: 100,
            document_id: paginationData[pagination.page][1],
            page: pagination.page + 1,
            search_text: null,
            filter: 0
        });
    };

    const onPreviousClick = () => {
        setPagination({
            limit: 100,
            document_id: paginationData[pagination.page - 1][0],
            page: pagination.page - 1,
            search_text: null,
            filter: false
        });
    };

    const onSearchClick = () => {
        if (searchText) {
            setPagination({
                limit: 100,
                document_id: null,
                page: -1,
                search_text: searchText,
                filter: 0
            });
        }
    };

    const onClearClick = () => {
        setSearchText("");
        setPagination({
            limit: 100,
            document_id: null,
            page: 0,
            search_text: null,
            filter: 0
        });
    };

    const onDeleteClick = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_index`;
        url = url + `?document_id=${index.id}`
        callApi(url, 'DELETE')
            .then((json) => {
                setSearchText("");
                setIndex({});
                setPagination({
                    limit: 100,
                    document_id: null,
                    page: 0,
                    search_text: null,
                    filter: pagination.filter
                });
                handleModalClose();
            })
            .catch((err) => err);
    }

    const onEditClick = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_index`;
        let merchantIndex = {
            id: index.id,
            mccs: index.mccs,
            category: index.dobin_category,
            display_name: index.display_name
        }
        callApi(url, 'PUT', {'merchant_index': merchantIndex})
            .then((json) => {
                setIndex({});
                handleModalClose();
                setPagination(_.cloneDeep(pagination));
            })
            .catch((err) => err);
    }

    return (
        <div className="merchant-index-page">
            <h1 className="title">
                Merchant Indexes
            </h1>
            <div className="row mt-40">
                <label className="col-md-4 fs-5">
                    Search by Display Name:
                </label>
                <input 
                    className="col-md-6" 
                    name="searchField" 
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)} />
            </div>
            <div className="merchant-index-container">
                <button
                    type="button"
                    className="btn-link bg-transparent border-0"
                    onClick={() => onFilterClick(2)}>
                        Unedited but matched indexes
                </button>
                <b> | </b>
                <button
                    type="button"
                    className="btn-link bg-transparent border-0"
                    onClick={() => onFilterClick(1)}>
                        Incorrect mcc-dobin_category mapped indexes
                </button>
                <button 
                    className="blue-button"
                    onClick={onSearchClick}>
                        Search
                </button>
                <button 
                    className="red-button"
                    onClick={onClearClick}>
                        Clear
                </button>
                {/* <button 
                    className="merchant-index-button"
                    type="button"
                    onClick={{}}>
                        Add
                </button> */}
            </div>
            <div className="mt-40">
                <DataTable
                    title={''}
                    columns={columns}
                    data={allIndexes ? allIndexes : []}
                    noDataComponent={responseMessage}
                    progressPending={pending}
                    onRowClicked={(row) => {
                        setIndex(row);
                        handleModalShow();
                    }}
                />
            </div>
            {
                pagination && !pagination.search_text && !pagination.filter ?
                    <div className="merchant-index-container">
                        <button 
                            className="merchant-index-button"
                            onClick={onPreviousClick}
                            disabled={pagination.page <= 0}>
                                Previous
                        </button>
                        <button 
                            className="merchant-index-button"
                            onClick={onNextClick}>
                                Next
                        </button>
                    </div>: 
                    null
            }
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Selected Merchant Index</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MerchantIndexAction 
                        merchant_index={index}
                        setMerchantIndex={setIndex} />
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="blue-button"
                        onClick={onEditClick}>
                            Save
                    </button>
                    <button 
                        className="red-button"
                        onClick={onDeleteClick}>
                            Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MerchantIndexesTableView;