import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import _ from "lodash"; 

import callApi from "../callApi";
import "./style.css";

const Users = () => {
    const [user, setUser] = useState({});
    const [data, setData] = useState({
        user_field: "",
        search_text: ""
    });

    const user_fields = [
        { value: 'id', label: 'Document Id' },
        { value: 'email_address', label: 'Email Address' },
        { value: 'mobile_number', label: 'Mobile Number' },
        { value: 'own_referral_code', label: 'Own Referral Code' },
    ];

    const searchUser = (searchData, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/users/user`;
        url = url + `?user_field=${searchData.user_field}&search_text=${encodeURIComponent(searchData.search_text)}`;
        callApi(url)
            .then((json) => {
                setUser(json.data.user);
                setSubmitting(false);
            })
            .catch((err) => err);
    };

    const onClear = (resetForm) => {
        setUser({});
        setData({
            user_field: "",
            search_text: ""
        });
        resetForm();
    };

    const renderObject = (data) => {
        return Object.keys(data).map((key, index) => {
            if (typeof(data[key]) == "string" || 
                typeof(data[key]) == "number" ||
                typeof(data[key]) == "boolean") {
                    return (
                        <div key={key + index} className="input-group mb-4">
                            <label className="input-group-text">{key}:</label>
                            <div className="form-control text-break">
                                {data[key].toString()}
                            </div>
                        </div>
                    );
            }
            else if (_.isArray(data[key])) {
                return (
                    <div key={key + index} className="input-group mb-4">
                        <label className="input-group-text">{key}:</label>
                        <div className="form-control text-break">
                            {data[key].toString()}
                        </div>
                    </div>
                );
            }
            else if (typeof(data[key]) == "object") {
                return (
                    <div key={key + index}>
                        <div className="input-group">
                            <label className="input-group-text">{key}:</label>
                        </div>
                        <div className="input-group mb-4">
                            <div className="p-4">
                                {renderObject(data[key])}
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    return (
        <div className="index-page">
            <h1 className="title">
                Users
            </h1>
            <Formik
                initialValues={data}
                validate={values => {
                    const errors = {};
                    if (!values.user_field) {
                        errors.user_field = 'Required';
                    }
                    if (!values.search_text) {
                        errors.search_text = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    searchUser(values, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue, resetForm }) => (
                    <Form>
                        <div className="row mt-40">
                            <label className="col-md-2">Search Field:*</label>
                            <Select
                                className="col-md-8"
                                name="user_field"
                                options={user_fields}
                                onChange={(selected) => setFieldValue("user_field", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="user_field" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Search Text:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="search_text" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="search_text" component="div" />
                        </div>
                        <div className="index-container">
                            <button 
                                className="blue-button"
                                type="submit" disabled={isSubmitting}>
                                    Search
                            </button>
                            <button 
                                className="red-button"
                                type="button"
                                onClick={() => onClear(resetForm)}>
                                    Clear
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {
                _.isEmpty(user) ?
                    null :
                    <div className="index-page">
                        <h2>
                            User Details
                        </h2>
                        <div className="index-container">
                            {
                                renderObject(user)
                            }
                        </div>
                    </div>
            }
        </div>
    );
};

export default Users;