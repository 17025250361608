export const conditionalRowStyles = [
    {
        when: row => row.imported,
        style: {
            backgroundColor: '#D1E2C4'
        },
    },
    {
        when: row => !row.imported && row.priceDiscountRate <= 0,
        style: {
            backgroundColor: '#FFCCCC'
        }
    }
]