export const columns = [
    {
        selector: row => row.shopName,
        name: "Shop Name",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.productName,
        name: "Product Name",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.productCatIds,
        format: row => row.productCatIds.join(", "),
        name: "Product Category IDs",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.offerLink,
        name: "Offer Link",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.periodEndTime,
        // Multiply periodEndTime by 1000 to convert to milliseconds and then generate the Date in UTC string format
        format: row => new Date(row.periodEndTime * 1000).toUTCString(),
        name: "Period End Time",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.periodStartTime,
        // Multiply periodStartTime by 1000 to convert to milliseconds and then generate the Date in UTC string format
        format: row => new Date(row.periodStartTime * 1000).toUTCString(),
        name: "Period Start Time",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.commissionRate,
        name: "Commission Rate",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.sellerCommissionRate,
        name: "Seller Commission Rate",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.shopeeCommissionRate,
        name: "Shopee Commission Rate",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.commission,
        name: "Commission Amount",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
];