import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';

import callApi from "../callApi";
import './style.css';


const Login = () => {
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState("");

    const responseGoogle = (credentialResponse) => {
        if (credentialResponse.credential) {
            authenticateUser(credentialResponse.credential);
        }
        else {
            setLoginError("Login Failed!");
        }
    };

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (userToken) {
            let url = `${process.env.REACT_APP_API_URL}/v1/core/login`;
            callApi(url, 'POST', {})
                .then((json) => {
                    navigate('/users');
                })
                .catch((err) => {
                    localStorage.clear();
                });
        }
    };

    useEffect(() => {
      checkUserToken();
    }, []);

    const authenticateUser = async(token) => {
        localStorage.clear();
        localStorage.setItem('user-token', token);
        let url = `${process.env.REACT_APP_API_URL}/v1/core/login`;
        callApi(url, 'POST', {})
            .then((json) => {
                navigate('/users');
            })
            .catch((err) => {
                localStorage.clear();
                setLoginError("Login Failed!");
            });
    };

    return (
        <div className="vh-100">
            <section id="signin" className="signup signin overflow-x-hidden">
                <div className="row d-flex align-items-center vh-100">
                    <div className="col-lg-5 col-xl-4 w-600">
                        <div className="bg-image-dobin border-right vh-100">
                            <div className="container px-0">
                                <div className="bg-lines">
                                    <div className="logo d-flex align-items-center pl-80">
                                        <img src="https://lender.dobin.io/static/img/logo-no-text.svg" alt="Logo" className="main-logo" />
                                    </div>
                                    <div className="title signin_title d-lg-block d-none">
                                        <h1>Admin Console</h1>
                                    </div>
                                    <div className="description sign-in-img pl-80 w-100 pt-73 d-lg-block d-none">
                                        <img src="https://lender.dobin.io/static/img/sign-in.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 w-800 m-pt-70">
                        <div className="row align-items-center justify-content-center tab-content">
                            <div className="col-lg-12">
                                <div className="title">
                                    <h2 className="text-start">Sign-in to your account</h2>
                                </div>
                                {
                                    loginError ? 
                                        <div className="text-danger">
                                            {loginError}
                                        </div>
                                    : null
                                }
                                <GoogleLogin
                                    width="250"
                                    logo_alignment="center"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    text="Login With Google" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;