import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import LoanOfferAction from "./action";
import { columns } from "./columns.js";
import callApi from "../callApi";
import "./style.css";


const LoanOfferTableView = () => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([]);
    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [loanOffer, setLoanOffer] = useState({});

    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        getLoanOffersData();
    }, [])

    const getLoanOffersData = () => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/loan_offers`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.loan_offers) {
                    setAllData(json.data.loan_offers);
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const onAddClick = () => {
        navigate('/loan-offers/add');
    }

    const deleteLoanOffer = () => {
        setButtonDisabled(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/loan_offers`;
        url = url + `?document_ids=${loanOffer.id}`
        callApi(url, 'DELETE')
            .then((json) => {
                setButtonDisabled(false);
                handleModalClose(); 
                getLoanOffersData();
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <h1 className="title">
                Loan Offers
            </h1>
            <div className="index-container">
                <button 
                    className="index-button"
                    onClick={onAddClick}>
                        Add
                </button>
            </div>
            <div className="mt-40">
                <DataTable
                    title={''}
                    columns={columns}
                    data={allData ? allData : []}
                    noDataComponent={responseMessage}
                    progressPending={pending}
                    onRowClicked={(row) => {
                        setLoanOffer(_.cloneDeep(row));
                        handleModalShow();
                    }}
                />
            </div>
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Selected Loan Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoanOfferAction 
                        loanOffer={loanOffer}
                        handleModalClose={handleModalClose}
                        setButtonDisabled={setButtonDisabled}
                        updateLoanOfferTable={() => {
                            getLoanOffersData()
                        }} />
                </Modal.Body>
                <Modal.Footer>
                {
                    <div>
                        <button
                            type="button"
                            className="red-button"
                            disabled={buttonDisabled}
                            onClick={() => deleteLoanOffer()}>
                                Delete
                        </button>
                        <button 
                            form="loan-offer-edit"
                            type="submit"
                            className="blue-button"
                            disabled={buttonDisabled}>
                                Save
                        </button>
                    </div>
                }   
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LoanOfferTableView;