import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Switch from "react-switch";
import _ from "lodash";

import callApi from "../callApi";

const ReferralCampaignAction = ({
    referralCampaign,
    handleModalClose,
    setButtonDisabled,
    updateReferralCampaignTable
}) => {
    const get_ddmmyyyy_date = (date_string) => {
        if (!date_string) {
            return "";
        }
        let date = new Date(date_string);

        let utc_date = date.getUTCDate();
        if (utc_date < 10) {
            utc_date = "0" + utc_date; 
        }

        let utc_month =  date.getUTCMonth();
        utc_month = utc_month + 1;
        if (utc_month < 10) {
            utc_month = "0" + utc_month;
        }

        let utc_year = date.getFullYear();

        return utc_year + "-" + utc_month + "-" + utc_date;
    };

    let data = _.cloneDeep(referralCampaign);
    data.from_date = get_ddmmyyyy_date(data.from_date);
    data.to_date = get_ddmmyyyy_date(data.to_date);

    const updateReferralCampaign = (referral_campaign, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/users/referral_campaigns`;
        callApi(url, 'PUT', {'referral_campaigns': [referral_campaign]})
            .then((json) => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleModalClose();
                updateReferralCampaignTable();
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <Formik
                initialValues={data}
                validate={values => {
                    const errors = {};
                    if (!values.id) {
                        errors.id = 'Required';
                    }
                    if (!values.from_date) {
                        errors.from_date = 'Required'
                    }
                    if (!values.to_date) {
                        errors.to_date = 'Required'
                    };
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    updateReferralCampaign(values, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form id="referral-campaign-edit">
                        <div className="row">
                            <label className="col-md-2">ID:*</label>
                            <Field 
                                className="col-md-8"
                                readOnly type="text" name="id" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="id" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">From Date:* (SGT = UTC - 1)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="from_date" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="from_date" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">To Date:* (SGT = UTC)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="to_date" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="to_date" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Use Impact:*</label>
                            <Switch
                                className="col-md-8"
                                checked={values.use_impact}
                                onChange={() => setFieldValue("use_impact", !values.use_impact)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="use_impact" component="div" />
                        </div>
                        {/* <div className="index-container">
                            <button 
                                className="index-button"
                                type="submit" disabled={isSubmitting}>
                                    Save
                            </button>
                        </div> */}
                    </Form>
                )}
                </Formik>
        </div>
    );
};


export default ReferralCampaignAction;