import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import _ from "lodash"; 

import DataTable from "../../components/DataTable";
import ShopeeAction from "./action";
import { conditionalRowStyles } from "./rows.js";
import { columns } from "./columns.js";
import callApi from "../callApi";
import "./style.css";

const ShopeeTableView = () => {
    const [allOffers, setAllOffers] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 50,
        page: 1
    });

    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [offer, setOffer] = useState({});

    const [importDisabled, setImportDisabled] = useState(false);

    useEffect(() => {
        getMerchantOffers(pagination);
    }, [pagination]);

    const getMerchantOffers = (paginationData) => {
        setPending(true);
        let paginationLimit =
            paginationData && "limit" in paginationData ? paginationData.limit : 50;
        let paginationPage =
            paginationData && "page" in paginationData ? paginationData.page : 1;
        let aggregator = 'shopee';
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/affiliate_merchant_offers`;
        url = url + `?aggregator=${aggregator}&limit=${paginationLimit}&page=${paginationPage}`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.affiliate_merchant_offers) {
                    setAllOffers(json.data.affiliate_merchant_offers);
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const updateMerchantOffers = (importedOffer) => {
        let offers = _.cloneDeep(allOffers);
        for (let offer of offers) {
            let offerId;
            if (offer.offerLink.includes('https://shope.ee/')) {
                offerId = offer.offerLink.split('https://shope.ee/')[1].trim().toLowerCase() 
            }
            else {
                offerId = offer.offerLink.split('https://s.shopee.sg/')[1].trim().toLowerCase()
            }
            if (offerId === importedOffer.source_id) {
                offer.imported = true;
            }
        }
        setAllOffers(offers);
    };

    const onNextClick = () => {
        setPagination({
            limit: 50,
            page: pagination.page + 1
        });
    };

    const onPreviousClick = () => {
        let newPage = pagination.page - 1
        setPagination({
            limit: 50,
            page: newPage >= 1 ? newPage : 1
        });
    };

    return (
        <div className="index-page">
            <h1 className="title">
                Shopee Offers
            </h1>
            <div className="mt-40">
                <DataTable
                    title={''}
                    columns={columns}
                    data={allOffers ? allOffers : []}
                    noDataComponent={responseMessage}
                    progressPending={pending}
                    onRowClicked={(row) => {
                        if (!row.imported && row.priceDiscountRate > 0) {
                            setOffer(_.cloneDeep(row));
                            handleModalShow();
                        }
                    }}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
            <div className="index-container">
                <button 
                    className="index-button"
                    onClick={onPreviousClick}
                    disabled={pagination.page <= 1}>
                        Previous
                </button>
                <button 
                    className="index-button"
                    onClick={onNextClick}>
                        Next
                </button>
            </div>
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Selected Shopee Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ShopeeAction 
                        shopeeOffer={offer}
                        handleModalClose={handleModalClose}
                        setImportDisabled={setImportDisabled}
                        updateMerchantOffers={updateMerchantOffers} />
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        form="shopee"
                        type="submit"
                        className="blue-button"
                        disabled={importDisabled}>
                            Import
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ShopeeTableView;