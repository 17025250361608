import React, { useState, useEffect } from "react";
import Select from 'react-select';
import _ from "lodash"; 

import callApi from "../callApi";

const MerchantIndexAction = ({
    merchant_index,
    setMerchantIndex
}) => {
    const [categories, setCategories] = useState([]);
    const [mccToCategoryMap, setMccToCategoryMap] = useState({});

    const getCategories = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/categories`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.categories) {
                    let categories = [];
                    let mccToCategories = {};
                    for (let category_id in json.data.categories) {
                        categories.push({
                            value: category_id,
                            label: category_id
                        });
                        if ('visa_mcc' in json.data.categories[category_id]) {
                            for (let mcc of json.data.categories[category_id]['visa_mcc']) {
                                mccToCategories[mcc] = category_id
                            }
                        }
                    }
                    setCategories(categories);
                    setMccToCategoryMap(mccToCategories);
                }
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getCategories();
        }
    }, [categories]);

    return (
        <div className="container">
            <div className="">
                <ul>
                    <li>Update merchant index: MCCs, category, name</li>
                    <li>Delete merchant index</li>
                </ul>
            </div>
            <div className="input-group mb-4">
                <label className="input-group-text">ID:</label>
                <div className="form-control">
                    {merchant_index.id}
                </div>
            </div>
            <div className="input-group mb-4">
                <label className="input-group-text">Merchant Names:</label>
                <div className="form-control">
                    {
                        merchant_index.merchant_names ?
                        merchant_index.merchant_names.join(", ") : null
                    }
                </div>
            </div>
            <div className="input-group mb-4">
                <label className="input-group-text">Mastercard Merchant IDs:</label>
                <div className="form-control">
                    {
                        merchant_index.mastercard_merchant_ids ?
                        merchant_index.mastercard_merchant_ids.join(", ") : null
                    }
                </div>
            </div>
            <div className="input-group mb-4">
                <label className="input-group-text">Visa Merchant IDs:</label>
                <div className="form-control">
                    {
                        merchant_index.visa_merchant_ids ?
                        merchant_index.visa_merchant_ids.join(", ") : null
                    }
                </div>
            </div>
            <div className="input-group mb-4">
                <label className="input-group-text">MCCs:</label>
                <div className="form-control">
                <Select
                    isMulti
                    name="mccs"
                    options={Object.keys(mccToCategoryMap).map(mcc => {
                        return {
                            label: mcc,
                            value: mcc
                        }
                    })}
                    defaultValue={
                        merchant_index.mccs ? merchant_index.mccs.map(mcc => {
                            return {
                                label: mcc,
                                value: mcc
                            };
                        }) : null
                    }
                    onChange={(selected) => {
                        let mccs = [];
                        for (let item of selected) {
                            mccs.push(item.value);
                        }
                        let index = _.cloneDeep(merchant_index);
                        index.mccs = mccs;
                        index.dobin_category = mccToCategoryMap[mccs[0]];
                        setMerchantIndex(index);
                    }} />
                </div>
            </div>
            <div className="input-group mb-4">
                <label className="input-group-text">Display Name:</label>
                <div className="form-control">
                <input 
                    className="col-md-12" 
                    name="displayName" 
                    value={merchant_index.display_name}
                    onChange={(e) => {
                        let index = _.cloneDeep(merchant_index);
                        index.display_name = e.target.value;
                        setMerchantIndex(index);
                    }} />
                </div>
            </div>
            <div className="input-group mb-4">
                <label className="input-group-text">Dobin Category:</label>
                <div className="form-control">
                    {merchant_index.dobin_category}
                </div>
            </div>
        </div>
    );
};

export default MerchantIndexAction;