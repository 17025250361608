import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Switch from "react-switch";

import callApi from "../callApi";
import { data } from "./data.js";

const AddReferralCampaigns = () => {
    const navigate = useNavigate();

    const saveReferralCampaign = (referral_campaign, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/users/referral_campaigns`;
        callApi(url, 'POST', {'referral_campaigns': [referral_campaign]})
            .then((json) => {
                setSubmitting(false);
                navigate('/referral-campaigns');
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <h1 className="title">
                Referral Campaigns - Add Form
            </h1>
            <Formik
                initialValues={data}
                validate={values => {
                    const errors = {};
                    if (!values.id) {
                        errors.id = 'Required';
                    }
                    if (!values.from_date) {
                        errors.from_date = 'Required'
                    }
                    if (!values.to_date) {
                        errors.to_date = 'Required'
                    };
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveReferralCampaign(values, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="row mt-40">
                            <label className="col-md-2">ID:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="id" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="id" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">From Date:* (SGT = UTC - 1)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="from_date" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="from_date" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">To Date:* (SGT = UTC)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="to_date" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="to_date" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Use Impact:*</label>
                            <Switch
                                className="col-md-8"
                                checked={values.use_impact}
                                onChange={() => setFieldValue("use_impact", !values.use_impact)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="use_impact" component="div" />
                        </div>
                        <div className="index-container">
                            <button 
                                className="index-button"
                                type="submit" disabled={isSubmitting}>
                                    Save
                            </button>
                        </div>
                    </Form>
                )}
                </Formik>
        </div>
    );
};


export default AddReferralCampaigns;