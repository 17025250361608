import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import CreditCardAction from "./action";
import FileUploadForm from "./upload";
import { columns } from "./columns.js";
import callApi from "../callApi";
import "./style.css";


const CreditCardTableView = () => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([]);
    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [showUploadModal, setUploadShowModal] = useState(false);
    const handleUploadModalClose = () => setUploadShowModal(false);
    const handleUploadModalShow = () => setUploadShowModal(true);

    const [creditCard, setCreditCard] = useState({});
    const [creditCardIds, setCreditCardIds] = useState([]);
    const [file, setFile] = useState(null);

    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        getCreditCardsData();
    }, [])

    const getCreditCardsData = () => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/credit_cards`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.credit_cards) {
                    setAllData(json.data.credit_cards);
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const onAddClick = () => {
        navigate('/credit-cards/add');
    }

    const downloadCreditCardsData = () => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/credit_cards/download`;
        callApi(url, 'POST', {'credit_card_ids': creditCardIds}, true)
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'credit_cards.csv';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setCreditCardIds([]);
                getCreditCardsData();
            })
            .catch((err) => err);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleFileUpload = async (operation) => {
        if (!file) {
            alert('No file selected');
            return;
        }

        if (operation !== 'add' && operation !== 'update') {
            alert('Invalid file operation')
            return;
        }

        setPending(true);
        setButtonDisabled(true);

        let url = `${process.env.REACT_APP_API_URL}/v1/providers/credit_cards/upload`;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_operation', operation);
        callApi(url, 'POST', formData, undefined, true)
            .then((json) => {
                if (json.success) {
                    alert("Bulk Upload Successful!");
                }
                else if (json.error) {
                    alert(json.error.toString());
                }
                setPending(false);
                setButtonDisabled(false);
                handleUploadModalClose();
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <h1 className="title">
                Credit Cards
            </h1>
            <div className="index-container">
                <button 
                    className="blue-button"
                    onClick={handleUploadModalShow}>
                        Upload
                </button>
                <button 
                    className="red-button"
                    disabled={creditCardIds.length <= 0 ? true : false}
                    onClick={downloadCreditCardsData}>
                        Download
                </button>
                <button 
                    className="index-button"
                    onClick={onAddClick}>
                        Add
                </button>
            </div>
            <div className="mt-40">
                <DataTable
                    title={''}
                    columns={columns}
                    data={allData ? allData : []}
                    noDataComponent={responseMessage}
                    progressPending={pending}
                    onRowClicked={(row) => {
                        setCreditCard(_.cloneDeep(row));
                        handleModalShow();
                    }}
                    selectableRows={true}
                    onSelectedRowsChange={(rows) => {
                        let row_ids = [];
                        rows.selectedRows.forEach((element, index) => {
                            row_ids.push(element.id);
                        });
                        setCreditCardIds(row_ids);
                    }}
                />
            </div>
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Selected Credit Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreditCardAction 
                        creditCard={creditCard}
                        handleModalClose={handleModalClose}
                        setButtonDisabled={setButtonDisabled}
                        updateCreditCardTable={() => {
                            getCreditCardsData()
                        }} />
                </Modal.Body>
                <Modal.Footer>
                {
                    <div>
                        <button 
                            form="credit-card-edit"
                            type="submit"
                            className="blue-button"
                            disabled={buttonDisabled}>
                                Save
                        </button>
                    </div>
                }   
                </Modal.Footer>
            </Modal>
            <Modal show={showUploadModal} onHide={handleUploadModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Upload Credit Card Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileUploadForm 
                        onFileChange={handleFileChange}
                        handleSubmit={handleFileUpload} />
                </Modal.Body>
                <Modal.Footer>
                {
                    <div>
                        <button
                            form="credit-card-upload"
                            type="submit"
                            className="blue-button"
                            disabled={buttonDisabled}>
                                Save
                        </button>
                    </div>
                }   
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreditCardTableView;