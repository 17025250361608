import React from "react";
import Table from "react-data-table-component";

import "./style.css";

const customStyles = {
    contextMenu: {
        style: {
            backgroundColor: "#34d0b6",
            fontSize: "14px",
            height: 40,
            minHeight: 40,
        },
    },
    headRow: {
        style: {
            backgroundColor: "#34d0b6",
            border: "1px solid #eceff7",
            borderBottom: "none",
            borderRadius: "5px 5px 0 0",
            minHeight: 48,
        },
    },
    headCells: {
        style: {
            fontWeight: 600,
            letterSpacing: "0.02rem",
            fontSize: 13,
        },
    },
    subHeader: {
        style: {
            padding: "10px 0",
        },
    },
    header: {
        style: {
            minHeight: 40,
        },
    },
    rows: {
        style: {
            padding: "16px 0",
            borderBottomColor: "#eceff7 !important",
            border: "1px solid #eceff7",
            borderTop: "none",
        },
    },
    expanderRow: {
        style: {
            padding: "16px 0",
            borderBottomColor: "#eceff7 !important",
            border: "1px solid #eceff7",
            borderTop: "none",
        },
    },
    pagination: {
        style: {
            border: "none",
            padding: "24px 8px",
        },
    },
};

const DataTable = ({
    title,
    columns,
    data,
    noDataComponent,
    progressPending,
    onRowClicked,
    conditionalRowStyles,
    selectableRows,
    onSelectedRowsChange
}) => {
    return (
        <div>
        <Table
            title={title}
            columns={columns}
            data={data}
            responsive
            striped
            highlightOnHover
            pointerOnHover
            noDataComponent={noDataComponent}
            progressPending={progressPending}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            customStyles={customStyles}
            onRowClicked={onRowClicked}
            conditionalRowStyles={conditionalRowStyles}
            selectableRows={selectableRows ? selectableRows : false}
            onSelectedRowsChange={onSelectedRowsChange} />
        </div>
    );
};

export default DataTable;