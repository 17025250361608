export const columns = [
    {
        selector: row => row.id,
        name: "ID",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.companyName,
        name: "Company Name",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.title,
        name: "Title",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.description,
        name: "Description",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.code,
        name: "Code",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.categoryId,
        name: "Category ID",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.categoryName,
        name: "Category Name",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.deepLinkURL,
        name: "DeepLink URL",
        maxWidth: '200px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.trackingURL,
        name: "Tracking URL",
        maxWidth: '200px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.deepLinkTrackingURL,
        name: "DeepLink Tracking URL",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.expiryDate,
        name: "Expiry Date",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.activationDate,
        name: "Activation Date",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.commission && row.commission.value,
        name: "Cost",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
];