import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import Switch from "react-switch";
import _ from "lodash";

import callApi from "../callApi.js";

const FaqAction = ({
    faq,
    handleModalClose,
    setButtonDisabled,
    updateFaqTable
}) => {
    let data = _.cloneDeep(faq);
    const [faqCategories, setFaqCategories] = useState([]);
    const [faqCountries, setFaqCountries] = useState([]);

    const getFaqCategories = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/core/faq_categories`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.faq_categories) {
                    let faq_category_ids = Object.keys(json.data.faq_categories);
                    let faq_categories = [];
                    for (let faq_category_id of faq_category_ids) {
                        faq_categories.push({
                            value: faq_category_id,
                            label: faq_category_id
                        });
                    }
                    setFaqCategories(faq_categories);
                }
            })
            .catch((err) => err);
    };

    const getFaqCountries = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/core/country_codes`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.country_codes) {
                    let faqCountries = [];
                    for (let country_code of json.data.country_codes) {
                        faqCountries.push({
                            value: country_code,
                            label: country_code
                        });
                    }
                    setFaqCountries(faqCountries);
                }
            })
            .catch((err) => err);
    };

    const dataTransformations = (faq) => {
        if ('rank' in faq) {
            delete faq['rank']
        }
        if (!faq['top_query']) {
            delete faq['top_query']
        }
        return faq;
    }

    const updateFAQ = (faq, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/core/faqs`;
        callApi(url, 'PUT', {'faqs': [faq]})
            .then((json) => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleModalClose();
                updateFaqTable();
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (faqCategories.length === 0) {
            getFaqCategories();
        }
    }, [faqCategories]);

    useEffect(() => {
        if (faqCountries.length === 0) {
            getFaqCountries();
        }
    }, [faqCountries]);

    return (
        <div className="index-page">
            <Formik
                initialValues={data}
                validate={values => {
                    const errors = {};
                    if (!values.category) {
                        errors.category = 'Required';
                    }
                    if (values.countries.length <= 0 || 
                        (values.countries.length > 0 && !values.countries[0])) {
                        errors.countries = 'Required';
                    }
                    if (!values.question) {
                        errors.question = 'Required'
                    }
                    if (!values.response) {
                        errors.response = 'Required'
                    };
                    if (values.tags.length <= 0 ||
                        (values.tags.length > 0 && !values.tags[0])) {
                        errors.tags = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setButtonDisabled(true);
                    let faq = dataTransformations(values);
                    updateFAQ(faq, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form id="faq-edit">
                        <div className="row mt-40">
                            <label className="col-md-2">Category:*</label>
                            <Select
                                className="col-md-8"
                                name="category"
                                options={faqCategories}
                                defaultValue={
                                    { 
                                        value: data.category, 
                                        label: data.category
                                    }
                                }
                                onChange={(selected) => setFieldValue("category", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="category" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Countries:*</label>
                            <Select
                                isMulti
                                className="col-md-8"
                                name="countries"
                                options={faqCountries}
                                defaultValue={
                                    data.countries.map(country => {
                                        return {
                                            label: country,
                                            value: country
                                        };
                                    })
                                }
                                onChange={(selected) => {
                                    let faqCountries = [];
                                    for (let item of selected) {
                                        faqCountries.push(item.value);
                                    }
                                    setFieldValue("countries", faqCountries);
                                }} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="countries" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Question:*</label>
                            <Field 
                                className="col-md-8"
                                as="textarea"
                                type="text" name="question" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="question" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Response:*</label>
                            <Field 
                                className="col-md-8"
                                as="textarea"
                                type="text" name="response" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="response" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Tags:*</label>
                            <FieldArray
                                className="col-md-8"
                                name="tags"
                                render={arrayHelpers => (
                                <div>
                                    {values.tags && values.tags.length > 0 ? (
                                    values.tags.map((tag, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`tags.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a tag from the list
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        {/* show this when user has removed all tags from the list */}
                                        Add Tags
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="tags" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Top Query:</label>
                            <Switch
                                className="col-md-8"
                                checked={values.top_query}
                                onChange={() => setFieldValue("top_query", !values.top_query)} />
                        </div>
                        {/* <div className="index-container">
                            <button 
                                className="index-button"
                                type="submit" disabled={isSubmitting}>
                                    Save
                            </button>
                        </div> */}
                    </Form>
                )}
                </Formik>
        </div>
    );
};


export default FaqAction;