export const categoryMap = {
    32:	"clothing",
    33:	"auto_and_transport",
    34:	"personal_care",
    35:	"books_and_supplies",
    36:	"public_transportation",
    37:	"electronics_and_software",
    72:	"shopping",
    38:	"electronics_and_software",
    67:	"food_and_dining",
    39:	"electronics_and_software",
    40:	"entertainment",
    64:	"clothing",
    65:	"income",
    41:	"gifts",
    42:	"food_and_dining",
    43:	"shopping",
    44:	"groceries",
    45:	"health_and_fitness",
    46:	"home",
    47:	"home_services",
    48:	"hotel",
    60:	"uncategorized",
    49:	"clothing",
    66:	"insurance",
    50:	"shopping",
    51:	"kids",
    69:	"shopping",
    61:	"uncategorized",
    52:	"bills_and_utilities",
    53:	"electronics_and_software",
    54:	"movies_and_music",
    63:	"uncategorized",
    70:	"shopping",
    71:	"shopping",
    68:	"wellness",
    56:	"health_and_fitness",
    57:	"travel",
    58:	"home_supplies",
};