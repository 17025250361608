const callApi = (url, method, body, blob, form) => {
    const errorHandler = (res) => {
        if (res.status === 401) {
            localStorage.clear();
            window.location.href = '/login';
            return res;
        }
        else if (res.status === 403) {
            alert('Invalid Request! Please contact Admin!');
            return res;
        }
        else if (res.status === 404) {
            alert('Resource Not Found!');
            return res;
        }
        else if (res.status === 200 && blob === true) {
            return res.blob();
        }
        return res.json();
    };

    if (!method) {
        method = 'GET'
    }

    if (method === 'POST' || method === 'PUT') {
        if (form) {
            return fetch(url, {
                mode: 'cors',
                method: method,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`
                },
                body: body
            }).then((res) => errorHandler(res));
        }
        else {
            return fetch(url, {
                mode: 'cors',
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`
                },
                body: JSON.stringify(body)
            }).then((res) => errorHandler(res));
        }
    }
    else {
        return fetch(url, {
            mode: 'cors',
            method: method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('user-token')}`
            }
        }).then((res) => errorHandler(res));
    }
}

export default callApi;