import { Routes, Route, BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./containers/Login";
import MerchantOfferTableView from "./containers/MerchantOffers/view";
import AddMerchantOffers from "./containers/MerchantOffers/add";
import Providers from "./containers/Providers";
import CreditCardTableView from "./containers/CreditCards/view";
import AddCreditCards from "./containers/CreditCards/add";
import FaqTableView from "./containers/Faqs/view";
import AddFaqs from "./containers/Faqs/add";
import LoanOfferTableView from "./containers/LoanOffers/view";
import AddLoanOffers from "./containers/LoanOffers/add";
import MerchantIndexesTableView from "./containers/MerchantIndexes/view";
import OptimiseMediaTableView from "./containers/OptimiseMedia/view";
import ReferralCampaignTableView from "./containers/ReferralCampaigns/view";
import ReferralCampaignsAdd from "./containers/ReferralCampaigns/add";
import RefreshCache from "./containers/RefreshCache";
import ShopeeTableView from "./containers/Shopee/view";
import Users from "./containers/Users";
import './App.css';


function App() {
    return (
        <div className="App">
            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route path="login" element={<Login />} />
                            <Route path="merchant-offers" element={
                                <ProtectedRoute>
                                    <MerchantOfferTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="merchant-offers/add" element={
                                <ProtectedRoute>
                                    <AddMerchantOffers />
                                </ProtectedRoute>
                            } />
                            <Route path="credit-cards" element={
                                <ProtectedRoute>
                                    <CreditCardTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="credit-cards/add" element={
                                <ProtectedRoute>
                                    <AddCreditCards />
                                </ProtectedRoute>
                            } />
                            <Route path="providers" element={
                                <ProtectedRoute>
                                    <Providers />
                                </ProtectedRoute>
                            } />
                            <Route path="faqs" element={
                                <ProtectedRoute>
                                    <FaqTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="faqs/add" element={
                                <ProtectedRoute>
                                    <AddFaqs />
                                </ProtectedRoute>
                            } />
                            <Route path="loan-offers" element={
                                <ProtectedRoute>
                                    <LoanOfferTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="loan-offers/add" element={
                                <ProtectedRoute>
                                    <AddLoanOffers />
                                </ProtectedRoute>
                            } />
                            <Route path="merchant-indexes" element={
                                <ProtectedRoute>
                                    <MerchantIndexesTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="optimise-media/merchant-offers" element={
                                <ProtectedRoute>
                                    <OptimiseMediaTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="referral-campaigns" element={
                                <ProtectedRoute>
                                    <ReferralCampaignTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="referral-campaigns/add" element={
                                <ProtectedRoute>
                                    <ReferralCampaignsAdd />
                                </ProtectedRoute>
                            } />
                            <Route path="refresh-cache" element={
                                <ProtectedRoute>
                                    <RefreshCache />
                                </ProtectedRoute>
                            } />
                            <Route path="shopee/merchant-offers" element={
                                <ProtectedRoute>
                                    <ShopeeTableView />
                                </ProtectedRoute>
                            } />
                            <Route path="users" element={
                                <ProtectedRoute>
                                    <Users />
                                </ProtectedRoute>
                            } />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </div>
    );
};

export default App;