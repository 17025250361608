import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import ReferralCampaignAction from "./action";
import { columns } from "./columns.js";
import callApi from "../callApi";
import "./style.css";


const ReferralCampaignTableView = () => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([]);
    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [referralCampaign, setReferralCampaign] = useState({});

    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        getReferralCampaignsData();
    }, [])

    const getReferralCampaignsData = () => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/users/referral_campaigns`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.referral_campaigns) {
                    setAllData(json.data.referral_campaigns);
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const onAddClick = () => {
        navigate('/referral-campaigns/add');
    }

    return (
        <div className="index-page">
            <h1 className="title">
                Referral Campaigns
            </h1>
            <div className="index-container">
                <button 
                    className="index-button"
                    onClick={onAddClick}>
                        Add
                </button>
            </div>
            <div className="mt-40">
                <DataTable
                    title={''}
                    columns={columns}
                    data={allData ? allData : []}
                    noDataComponent={responseMessage}
                    progressPending={pending}
                    onRowClicked={(row) => {
                        setReferralCampaign(_.cloneDeep(row));
                        handleModalShow();
                    }}
                />
            </div>
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Selected Referral Campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReferralCampaignAction 
                        referralCampaign={referralCampaign}
                        handleModalClose={handleModalClose}
                        setButtonDisabled={setButtonDisabled}
                        updateReferralCampaignTable={() => {
                            getReferralCampaignsData()
                        }} />
                </Modal.Body>
                <Modal.Footer>
                {
                    <div>
                        <button 
                            form="referral-campaign-edit"
                            type="submit"
                            className="blue-button"
                            disabled={buttonDisabled}>
                                Save
                        </button>
                    </div>
                }   
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ReferralCampaignTableView;