export const columns = [
    {
        selector: row => row.id,
        name: "ID",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.provider_id,
        name: "Provider ID",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.name,
        name: "Name",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.primary_type,
        name: "Primary Type",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.reward.type,
        name: "Reward Type",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        cell: row => <img 
            height="100%" width="100%" 
            alt={row.image_url} src={row.image_url}
            data-tag="allowRowEvents" />,
        name: "Image URL",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.created_datetime,
        name: "Created Datetime",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.updated_datetime,
        name: "Updated Datetime",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
];