import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { 
    CDBSidebar, CDBSidebarContent, CDBSidebarHeader, CDBSidebarMenu,
    CDBSidebarMenuItem, CDBNavbar, CDBNavBrand, CDBSidebarFooter
} from 'cdbreact';
import { googleLogout } from '@react-oauth/google';

import "./style.css";


const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    };

    useEffect(() => {
      checkUserToken();
    }, [isLoggedIn]);

    const clearUserToken = () => {
        localStorage.clear();
        googleLogout();
    };

    return (
        <React.Fragment>
            <div className="app-navbar-container">
                <CDBNavbar className="app-navbar" light expand="md" scrolling double>
                    <CDBNavBrand href="/" className="fs-3">
                        Dobin - Admin Console
                    </CDBNavBrand>
                </CDBNavbar>
            </div>
            <div className="row vh-100">
                <div className={"col-auto"}>
                    <CDBSidebar textColor="#000000" backgroundColor="#f0f0f0" toggled={hideSidebar}>
                        <CDBSidebarHeader 
                            onClick={() => {setHideSidebar(!hideSidebar)}}
                            prefix={<i className="fa fa-bars" />}>Menu</CDBSidebarHeader>
                        <CDBSidebarContent>
                            <CDBSidebarMenu>
                                <NavLink to="/users">
                                    <CDBSidebarMenuItem icon="table">Users</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/refresh-cache">
                                    <CDBSidebarMenuItem icon="table">Refresh Cache</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/referral-campaigns">
                                    <CDBSidebarMenuItem icon="table">Referral Campaigns</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/providers">
                                    <CDBSidebarMenuItem icon="table">Providers</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/faqs">
                                    <CDBSidebarMenuItem icon="table">FAQs</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/credit-cards">
                                    <CDBSidebarMenuItem icon="table">Credit Cards</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/loan-offers">
                                    <CDBSidebarMenuItem icon="table">Loan Offers</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/merchant-indexes">
                                    <CDBSidebarMenuItem icon="table">Merchant Indexes</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/merchant-offers">
                                    <CDBSidebarMenuItem icon="table">Merchant Offers</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/optimise-media/merchant-offers">
                                    <CDBSidebarMenuItem icon="table">Optimise Media Offers</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink to="/shopee/merchant-offers">
                                    <CDBSidebarMenuItem icon="table">Shopee Offers</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink onClick={clearUserToken} to="/login">
                                    <CDBSidebarMenuItem icon="user">Sign Out</CDBSidebarMenuItem>
                                </NavLink>
                            </CDBSidebarMenu>
                        </CDBSidebarContent>
                        {/* <CDBSidebarFooter style={{ textAlign: 'center' }}>
                            <div
                                className="sidebar-btn-wrapper"
                                style={{padding: '20px 5px'}}>
                                Dobin. All Rights Reserved.
                            </div>
                        </CDBSidebarFooter> */}
                    </CDBSidebar>
                </div>
                <div className={hideSidebar ? "col-md-11": "col-md-9"}>
                {
                    isLoggedIn ? props.children : null
                }
                </div>
            </div>
        </React.Fragment>
    );
}
export default ProtectedRoute;