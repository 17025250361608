export const categoryMap = {
    0: "uncategorized",
    100001: "wellness",
    100002: "wellness",
    100003: "wellness",
    100004: "wellness",
    100005: "wellness",
    100006: "wellness",
    100007: "wellness",
    100008: "wellness",
    100009: "shopping",
    100010: "home_supplies",
    100011: "clothing",
    100012: "shopping",
    100013: "electronics_and_software",
    100014: "clothing",
    100015: "travel",
    100016: "shopping",
    100017: "clothing",
    100018: "wellness",
    100019: "wellness",
    100020: "wellness",
    100021: "shopping",
    100022: "shopping",
    100023: "shopping",
    100024: "shopping",
    100025: "shopping",
    100026: "shopping",
    100027: "shopping",
    100028: "shopping",
    100029: "shopping",
    100030: "shopping",
    100031: "shopping",
    100032: "shopping",
    100033: "shopping",
    100034: "shopping",
    100035: "shopping",
    100036: "shopping",
    100037: "home_improvement",
    100038: "home_improvement",
    100039: "home_improvement",
    100040: "home_improvement",
    100041: "home_improvement",
    100042: "home",
    100043: "home_supplies",
    100044: "home",
    100045: "home_supplies",
    100046: "home_supplies",
    100047: "clothing",
    100048: "clothing",
    100049: "clothing",
    100050: "clothing",
    100051: "clothing",
    100052: "clothing",
    100053: "clothing",
    100054: "clothing",
    100055: "clothing",
    100056: "clothing",
    100057: "clothing",
    100058: "clothing",
    100059: "clothing",
    100060: "clothing",
    100061: "clothing",
    100062: "clothing",
    100063: "shopping",
    100064: "shopping",
    100065: "shopping",
    100066: "shopping",
    100067: "shopping",
    100068: "shopping",
    100069: "shopping",
    100070: "shopping",
    100071: "phone",
    100072: "electronics_and_software",
    100073: "electronics_and_software",
    100074: "electronics_and_software",
    100075: "electronics_and_software",
    100076: "electronics_and_software",
    100077: "electronics_and_software",
    100078: "clothing",
    100079: "clothing",
    100080: "clothing",
    100081: "clothing",
    100082: "clothing",
    100083: "clothing",
    100084: "clothing",
    100085: "shopping",
    100086: "shopping",
    100087: "shopping",
    100088: "shopping",
    100089: "shopping",
    100090: "shopping",
    100091: "shopping",
    100092: "shopping",
    100093: "shopping",
    100094: "shopping",
    100095: "shopping",
    100096: "shopping",
    100097: "shopping",
    100098: "shopping",
    100099: "clothing",
    100100: "clothing",
    100101: "clothing",
    100102: "clothing",
    100103: "clothing",
    100104: "clothing",
    100105: "clothing",
    100106: "clothing",
    100107: "clothing",
    100108: "clothing",
    100109: "clothing",
    100110: "clothing",
    100111: "clothing",
    100112: "clothing",
    100113: "clothing",
    100114: "clothing",
    100115: "clothing",
    100116: "clothing",
    100117: "clothing",
    100118: "clothing",
    100119: "wellness",
    100120: "wellness",
    100121: "wellness",
    100122: "wellness",
    100123: "wellness",
    100124: "wellness",
    100125: "doctor",
    100126: "pharmacy",
    100127: "doctor",
    100128: "doctor",
    100129: "doctor",
    100130: "personal_care",
    100131: "pharmacy",
    100132: "pharmacy",
    100133: "pharmacy",
    100134: "pharmacy",
    100135: "personal_care",
    100136: "personal_care",
    100137: "personal_care",
    100138: "personal_care",
    100139: "personal_care",
    100140: "personal_care",
    100141: "personal_care",
    100142: "wellness",
    100143: "personal_care",
    100144: "personal_care",
    100145: "shopping",
    100146: "shopping",
    100147: "shopping",
    100148: "shopping",
    100149: "shopping",
    100150: "shopping",
    100151: "shopping",
    100152: "shopping",
    100153: "shopping",
    100154: "shopping",
    100155: "shopping",
    100156: "shopping",
    100157: "shopping",
    100158: "shopping",
    100159: "shopping",
    100160: "shopping",
    100161: "shopping",
    100162: "shopping",
    100163: "shopping",
    100164: "shopping",
    100165: "shopping",
    100166: "shopping",
    100167: "shopping",
    100168: "home_supplies",
    100169: "home_supplies",
    100170: "home_supplies",
    100171: "home_supplies",
    100172: "home_supplies",
    100173: "home_supplies",
    100174: "home",
    100175: "home_supplies",
    100176: "home",
    100177: "home_supplies",
    100178: "home_supplies",
    100179: "home_improvement",
    100180: "home_supplies",
    100181: "home",
    100182: "home_supplies",
    100183: "home_supplies",
    100184: "home",
    100185: "home_supplies",
    100186: "home_supplies",
    100187: "home_supplies",
    100188: "home_supplies",
    100189: "home_supplies",
    100190: "home_supplies",
    100191: "home_supplies",
    100192: "home_supplies",
    100193: "home_supplies",
    100194: "home_supplies",
    100195: "home_supplies",
    100196: "home_supplies",
    100197: "home_supplies",
    100198: "home_supplies",
    100199: "home_supplies",
    100200: "home_supplies",
    100201: "home_supplies",
    100202: "home_supplies",
    100203: "home_supplies",
    100204: "home_supplies",
    100205: "home_supplies",
    100206: "home_supplies",
    100207: "home_supplies",
    100208: "home_improvement",
    100209: "home_supplies",
    100210: "home_supplies",
    100211: "home_supplies",
    100212: "home",
    100213: "home_supplies",
    100214: "home_supplies",
    100215: "home_supplies",
    100216: "home_supplies",
    100217: "home_supplies",
    100218: "home_supplies",
    100219: "home_supplies",
    100220: "home",
    100221: "home",
    100222: "home",
    100223: "home",
    100224: "home",
    100225: "home",
    100226: "clothing",
    100227: "clothing",
    100228: "clothing",
    100229: "clothing",
    100230: "clothing",
    100231: "clothing",
    100232: "clothing",
    100233: "clothing",
    100234: "clothing",
    100235: "clothing",
    100236: "clothing",
    100237: "clothing",
    100238: "clothing",
    100239: "clothing",
    100240: "clothing",
    100241: "clothing",
    100242: "clothing",
    100243: "clothing",
    100244: "clothing",
    100245: "clothing",
    100246: "clothing",
    100247: "clothing",
    100248: "clothing",
    100249: "clothing",
    100250: "clothing",
    100251: "clothing",
    100252: "clothing",
    100253: "clothing",
    100254: "clothing",
    100255: "shopping",
    100256: "shopping",
    100257: "shopping",
    100258: "shopping",
    100259: "shopping",
    100260: "shopping",
    100261: "shopping",
    100262: "shopping",
    100263: "shopping",
    100264: "shopping",
    100265: "shopping",
    100266: "shopping",
    100267: "shopping",
    100268: "shopping",
    100269: "shopping",
    100270: "electronics_and_software",
    100271: "electronics_and_software",
    100272: "electronics_and_software",
    100273: "electronics_and_software",
    100274: "electronics_and_software",
    100275: "electronics_and_software",
    100276: "electronics_and_software",
    100277: "electronics_and_software",
    100278: "electronics_and_software",
    100279: "electronics_and_software",
    100280: "electronics_and_software",
    100281: "electronics_and_software",
    100282: "electronics_and_software",
    100283: "electronics_and_software",
    100284: "electronics_and_software",
    100285: "electronics_and_software",
    100286: "electronics_and_software",
    100287: "electronics_and_software",
    100288: "electronics_and_software",
    100289: "electronics_and_software",
    100290: "electronics_and_software",
    100291: "electronics_and_software",
    100292: "clothing",
    100293: "clothing",
    100294: "clothing",
    100295: "clothing",
    100296: "clothing",
    100297: "clothing",
    100298: "clothing",
    100299: "clothing",
    100300: "clothing",
    100301: "clothing",
    100302: "clothing",
    100303: "clothing",
    100304: "clothing",
    100305: "clothing",
    100306: "clothing",
    100307: "clothing",
    100308: "clothing",
    100309: "clothing",
    100310: "clothing",
    100311: "clothing",
    100312: "clothing",
    100313: "clothing",
    100314: "clothing",
    100315: "clothing",
    100316: "clothing",
    100317: "clothing",
    100318: "clothing",
    100319: "clothing",
    100320: "travel",
    100321: "travel",
    100322: "travel",
    100323: "shopping",
    100324: "travel",
    100325: "travel",
    100326: "travel",
    100327: "travel",
    100328: "travel",
    100329: "travel",
    100330: "travel",
    100331: "travel",
    100332: "travel",
    100333: "shopping",
    100334: "shopping",
    100335: "shopping",
    100336: "shopping",
    100337: "shopping",
    100338: "shopping",
    100339: "shopping",
    100340: "shopping",
    100341: "shopping",
    100342: "shopping",
    100343: "shopping",
    100344: "shopping",
    100345: "shopping",
    100346: "shopping",
    100347: "shopping",
    100348: "shopping",
    100349: "shopping",
    100350: "clothing",
    100351: "clothing",
    100352: "clothing",
    100353: "clothing",
    100354: "clothing",
    100355: "clothing",
    100356: "clothing",
    100357: "clothing",
    100358: "clothing",
    100359: "clothing",
    100360: "clothing",
    100361: "clothing",
    100362: "clothing",
    100363: "clothing",
    100364: "clothing",
    100365: "clothing",
    100366: "clothing",
    100367: "clothing",
    100368: "clothing",
    100369: "clothing",
    100370: "clothing",
    100371: "clothing",
    100372: "clothing",
    100373: "clothing",
    100374: "clothing",
    100375: "clothing",
    100376: "clothing",
    100377: "clothing",
    100378: "clothing",
    100379: "clothing",
    100380: "clothing",
    100381: "clothing",
    100382: "clothing",
    100383: "clothing",
    100384: "clothing",
    100385: "clothing",
    100386: "clothing",
    100387: "clothing",
    100388: "clothing",
    100389: "clothing",
    100390: "clothing",
    100391: "clothing",
    100392: "clothing",
    100393: "clothing",
    100394: "clothing",
    100395: "clothing",
    100396: "clothing",
    100397: "clothing",
    100398: "clothing",
    100399: "clothing",
    100400: "clothing",
    100401: "clothing",
    100402: "clothing",
    100403: "clothing",
    100404: "clothing",
    100405: "clothing",
    100406: "clothing",
    100407: "clothing",
    100408: "clothing",
    100409: "clothing",
    100410: "clothing",
    100411: "clothing",
    100412: "clothing",
    100413: "clothing",
    100414: "clothing",
    100415: "clothing",
    100416: "clothing",
    100417: "clothing",
    100418: "clothing",
    100419: "clothing",
    100420: "health_and_fitness",
    100421: "health_and_fitness",
    100422: "health_and_fitness",
    100423: "health_and_fitness",
    100424: "health_and_fitness",
    100425: "personal_care",
    100426: "health_and_fitness",
    100427: "personal_care",
    100428: "personal_care",
    100429: "personal_care",
    100430: "wellness",
    100431: "wellness",
    100432: "wellness",
    100433: "wellness",
    100434: "wellness",
    100435: "pharmacy",
    100436: "pharmacy",
    100437: "personal_care",
    100438: "personal_care",
    100439: "personal_care",
    100440: "personal_care",
    100441: "personal_care",
    100442: "personal_care",
    100443: "personal_care",
    100444: "personal_care",
    100445: "personal_care",
    100446: "personal_care",
    100447: "personal_care",
    100448: "personal_care",
    100449: "personal_care",
    100450: "personal_care",
    100451: "personal_care",
    100452: "personal_care",
    100453: "personal_care",
    100454: "home_supplies",
    100455: "home_supplies",
    100456: "home_supplies",
    100457: "home",
    100458: "home_supplies",
    100459: "home_supplies",
    100460: "home",
    100461: "home_supplies",
    100462: "home_supplies",
    100463: "home",
    100464: "home_supplies",
    100465: "home_supplies",
    100466: "home_supplies",
    100467: "home",
    100468: "home_supplies",
    100469: "home_supplies",
    100470: "home_supplies",
    100471: "home_supplies",
    100472: "home_supplies",
    100473: "home_supplies",
    100474: "home_supplies",
    100475: "electronics_and_software",
    100476: "electronics_and_software",
    100477: "electronics_and_software",
    100478: "electronics_and_software",
    100479: "electronics_and_software",
    100480: "electronics_and_software",
    100481: "electronics_and_software",
    100482: "electronics_and_software",
    100483: "electronics_and_software",
    100484: "electronics_and_software",
    100485: "electronics_and_software",
    100486: "electronics_and_software",
    100487: "electronics_and_software",
    100488: "electronics_and_software",
    100489: "electronics_and_software",
    100490: "electronics_and_software",
    100491: "electronics_and_software",
    100492: "clothing",
    100493: "clothing",
    100494: "clothing",
    100495: "clothing",
    100496: "clothing",
    100497: "clothing",
    100498: "clothing",
    100499: "clothing",
    100500: "clothing",
    100501: "clothing",
    100502: "clothing",
    100503: "clothing",
    100504: "clothing",
    100505: "clothing",
    100506: "clothing",
    100507: "clothing",
    100508: "clothing",
    100509: "clothing",
    100510: "clothing",
    100511: "clothing",
    100512: "clothing",
    100513: "clothing",
    100514: "clothing",
    100515: "clothing",
    100516: "clothing",
    100517: "clothing",
    100518: "clothing",
    100519: "clothing",
    100520: "clothing",
    100521: "clothing",
    100522: "clothing",
    100523: "clothing",
    100524: "clothing",
    100525: "clothing",
    100526: "clothing",
    100527: "clothing",
    100528: "clothing",
    100529: "clothing",
    100531: "cafes_and_restaurants",
    100532: "shopping",
    100533: "shopping",
    100534: "shopping",
    100535: "home_supplies",
    100536: "cafes_and_restaurants",
    100537: "cafes_and_restaurants",
    100538: "cafes_and_restaurants",
    100539: "cafes_and_restaurants",
    100540: "cafes_and_restaurants",
    100541: "cafes_and_restaurants",
    100542: "cafes_and_restaurants",
    100543: "cafes_and_restaurants",
    100544: "cafes_and_restaurants",
    100545: "cafes_and_restaurants",
    100546: "cafes_and_restaurants",
    100547: "cafes_and_restaurants",
    100548: "cafes_and_restaurants",
    100549: "cafes_and_restaurants",
    100550: "cafes_and_restaurants",
    100551: "cafes_and_restaurants",
    100552: "cafes_and_restaurants",
    100553: "cafes_and_restaurants",
    100554: "cafes_and_restaurants",
    100555: "cafes_and_restaurants",
    100556: "shopping",
    100557: "shopping",
    100558: "shopping",
    100559: "shopping",
    100560: "shopping",
    100561: "shopping",
    100562: "shopping",
    100563: "shopping",
    100564: "shopping",
    100565: "shopping",
    100566: "shopping",
    100567: "shopping",
    100568: "shopping",
    100569: "shopping",
    100570: "shopping",
    100571: "shopping",
    100572: "shopping",
    100573: "shopping",
    100574: "shopping",
    100575: "shopping",
    100576: "shopping",
    100577: "shopping",
    100578: "home_supplies",
    100579: "home_supplies",
    100580: "home_supplies",
    100581: "home_supplies",
    100582: "home_supplies",
    100583: "home_supplies",
    100584: "home_supplies",
    100585: "shopping",
    100586: "shopping",
    100587: "shopping",
    100588: "shopping",
    100589: "shopping",
    100590: "shopping",
    100591: "shopping",
    100592: "shopping",
    100593: "shopping",
    100594: "shopping",
    100595: "shopping",
    100596: "shopping",
    100597: "shopping",
    100598: "shopping",
    100599: "shopping",
    100600: "shopping",
    100601: "shopping",
    100602: "shopping",
    100603: "shopping",
    100604: "shopping",
    100605: "shopping",
    100606: "shopping",
    100607: "shopping",
    100608: "shopping",
    100609: "shopping",
    100610: "shopping",
    100611: "shopping",
    100612: "shopping",
    100613: "shopping",
    100614: "shopping",
    100615: "shopping",
    100616: "shopping",
    100617: "shopping",
    100618: "shopping",
    100619: "shopping",
    100620: "home_supplies",
    100621: "home_supplies",
    100622: "home_supplies",
    100623: "home_supplies",
    100624: "home_supplies",
    100625: "home_supplies",
    100626: "home_supplies",
    100627: "home_supplies",
    100628: "home_supplies",
    100629: "groceries",
    100630: "personal_care",
    100631: "pets",
    100632: "baby_supplies",
    100633: "baby_supplies",
    100634: "games",
    100635: "electronics_and_software",
    100636: "home_supplies",
    100637: "sports",
    100638: "books_and_supplies",
    100639: "entertainment",
    100640: "automotive",
    100641: "automotive",
    100642: "uncategorized",
    100643: "books_and_supplies",
    100644: "electronics_and_software",
    100645: "groceries",
    100646: "groceries",
    100647: "groceries",
    100648: "groceries",
    100649: "groceries",
    100650: "groceries",
    100651: "groceries",
    100652: "groceries",
    100653: "groceries",
    100654: "groceries",
    100655: "groceries",
    100656: "groceries",
    100657: "groceries",
    100658: "personal_care",
    100659: "personal_care",
    100660: "personal_care",
    100661: "personal_care",
    100662: "personal_care",
    100663: "personal_care",
    100664: "personal_care",
    100665: "personal_care",
    100666: "personal_care",
    100667: "pet_food_and_supplies",
    100668: "pet_food_and_supplies",
    100669: "pet_food_and_supplies",
    100670: "pet_grooming",
    100671: "pet_food_and_supplies",
    100672: "pet_grooming",
    100673: "pet_grooming",
    100674: "baby_supplies",
    100675: "baby_supplies",
    100676: "baby_supplies",
    100677: "baby_supplies",
    100678: "baby_supplies",
    100679: "baby_supplies",
    100680: "baby_supplies",
    100681: "baby_supplies",
    100682: "baby_supplies",
    100683: "baby_supplies",
    100684: "toys",
    100685: "baby_supplies",
    100686: "kids",
    100687: "baby_supplies",
    100688: "baby_supplies",
    100689: "baby_supplies",
    100690: "baby_supplies",
    100691: "baby_supplies",
    100692: "baby_supplies",
    100693: "baby_supplies",
    100694: "baby_supplies",
    100695: "games",
    100696: "games",
    100697: "games",
    100698: "games",
    100699: "electronics_and_software",
    100700: "electronics_and_software",
    100701: "electronics_and_software",
    100702: "electronics_and_software",
    100703: "electronics_and_software",
    100704: "electronics_and_software",
    100705: "electronics_and_software",
    100706: "electronics_and_software",
    100707: "electronics_and_software",
    100708: "home_improvement",
    100709: "home_improvement",
    100710: "home_improvement",
    100711: "home_improvement",
    100712: "home_improvement",
    100713: "home_improvement",
    100714: "home_improvement",
    100715: "home_improvement",
    100716: "home_supplies",
    100717: "home_supplies",
    100718: "home_supplies",
    100719: "home_supplies",
    100720: "home_supplies",
    100721: "home_supplies",
    100722: "home_supplies",
    100723: "home_supplies",
    100724: "home_supplies",
    100725: "sporting_goods",
    100726: "sporting_goods",
    100727: "sporting_goods",
    100728: "sporting_goods",
    100729: "sporting_goods",
    100730: "office_supplies",
    100731: "office_supplies",
    100732: "office_supplies",
    100733: "office_supplies",
    100734: "office_supplies",
    100735: "office_supplies",
    100736: "office_supplies",
    100737: "entertainment",
    100738: "entertainment",
    100739: "games",
    100740: "entertainment",
    100741: "movies_and_music",
    100742: "movies_and_music",
    100743: "entertainment",
    100744: "entertainment",
    100745: "entertainment",
    100746: "automotive",
    100747: "service_and_parts",
    100748: "service_and_parts",
    100749: "service_and_parts",
    100750: "service_and_parts",
    100751: "service_and_parts",
    100752: "service_and_parts",
    100753: "service_and_parts",
    100754: "automotive",
    100755: "service_and_parts",
    100756: "service_and_parts",
    100757: "service_and_parts",
    100758: "service_and_parts",
    100759: "service_and_parts",
    100760: "entertainment",
    100761: "food_and_dining",
    100762: "shopping",
    100763: "utilities",
    100764: "business_services",
    100765: "wellness",
    100766: "automotive",
    100767: "education",
    100768: "phone",
    100769: "travel",
    100770: "transfer",
    100771: "business_services",
    100772: "games",
    100773: "television",
    100774: "shopping",
    100775: "uncategorized",
    100776: "newspapers_and_magazines",
    100777: "books_and_supplies",
    100778: "books_and_supplies",
    100779: "books_and_supplies",
    100780: "groceries",
    100781: "groceries",
    100782: "groceries",
    100783: "groceries",
    100784: "groceries",
    100785: "groceries",
    100786: "groceries",
    100787: "groceries",
    100788: "groceries",
    100789: "groceries",
    100790: "groceries",
    100791: "groceries",
    100792: "groceries",
    100793: "groceries",
    100794: "groceries",
    100795: "groceries",
    100796: "groceries",
    100797: "groceries",
    100798: "groceries",
    100799: "groceries",
    100800: "groceries",
    100801: "groceries",
    100802: "groceries",
    100803: "groceries",
    100804: "groceries",
    100805: "groceries",
    100806: "groceries",
    100807: "groceries",
    100808: "groceries",
    100809: "groceries",
    100810: "groceries",
    100811: "groceries",
    100812: "groceries",
    100813: "groceries",
    100814: "groceries",
    100815: "groceries",
    100816: "groceries",
    100817: "groceries",
    100818: "groceries",
    100819: "groceries",
    100820: "groceries",
    100821: "groceries",
    100822: "groceries",
    100823: "groceries",
    100824: "groceries",
    100825: "groceries",
    100826: "groceries",
    100827: "groceries",
    100828: "groceries",
    100829: "groceries",
    100830: "groceries",
    100831: "groceries",
    100832: "groceries",
    100833: "groceries",
    100834: "groceries",
    100835: "groceries",
    100836: "groceries",
    100837: "groceries",
    100838: "groceries",
    100839: "groceries",
    100840: "groceries",
    100841: "groceries",
    100842: "groceries",
    100843: "groceries",
    100844: "groceries",
    100845: "groceries",
    100846: "groceries",
    100847: "groceries",
    100848: "groceries",
    100849: "groceries",
    100850: "groceries",
    100851: "groceries",
    100852: "groceries",
    100853: "groceries",
    100854: "groceries",
    100855: "groceries",
    100856: "groceries",
    100857: "groceries",
    100858: "groceries",
    100859: "groceries",
    100860: "groceries",
    100861: "groceries",
    100862: "groceries",
    100863: "groceries",
    100864: "groceries",
    100865: "personal_care",
    100866: "personal_care",
    100867: "personal_care",
    100868: "personal_care",
    100869: "personal_care",
    100870: "personal_care",
    100871: "personal_care",
    100872: "personal_care",
    100873: "personal_care",
    100874: "personal_care",
    100875: "personal_care",
    100876: "personal_care",
    100877: "personal_care",
    100878: "personal_care",
    100879: "personal_care",
    100880: "personal_care",
    100881: "personal_care",
    100882: "personal_care",
    100883: "personal_care",
    100884: "personal_care",
    100885: "personal_care",
    100886: "personal_care",
    100887: "personal_care",
    100888: "personal_care",
    100889: "personal_care",
    100890: "personal_care",
    100891: "personal_care",
    100892: "personal_care",
    100893: "personal_care",
    100894: "personal_care",
    100895: "personal_care",
    100896: "personal_care",
    100897: "personal_care",
    100898: "personal_care",
    100899: "personal_care",
    100900: "personal_care",
    100901: "personal_care",
    100902: "personal_care",
    100903: "personal_care",
    100904: "personal_care",
    100905: "personal_care",
    100906: "pet_food_and_supplies",
    100907: "pet_food_and_supplies",
    100908: "pet_food_and_supplies",
    100909: "pet_food_and_supplies",
    100910: "pet_food_and_supplies",
    100911: "pet_food_and_supplies",
    100912: "pet_food_and_supplies",
    100913: "pet_food_and_supplies",
    100914: "pet_food_and_supplies",
    100915: "pet_food_and_supplies",
    100916: "pet_food_and_supplies",
    100917: "pet_food_and_supplies",
    100918: "pet_food_and_supplies",
    100919: "pet_food_and_supplies",
    100920: "pet_food_and_supplies",
    100921: "pet_food_and_supplies",
    100922: "pet_food_and_supplies",
    100923: "pet_food_and_supplies",
    100924: "pet_food_and_supplies",
    100925: "pet_food_and_supplies",
    100926: "pet_food_and_supplies",
    100927: "pet_food_and_supplies",
    100928: "pet_food_and_supplies",
    100929: "pet_grooming",
    100930: "pet_grooming",
    100931: "pet_grooming",
    100932: "pet_grooming",
    100933: "pet_food_and_supplies",
    100934: "pet_food_and_supplies",
    100935: "pet_food_and_supplies",
    100936: "pet_food_and_supplies",
    100937: "pet_food_and_supplies",
    100938: "pet_food_and_supplies",
    100939: "pet_food_and_supplies",
    100940: "pet_food_and_supplies",
    100941: "pet_grooming",
    100942: "pet_grooming",
    100943: "pet_grooming",
    100944: "pet_grooming",
    100945: "baby_supplies",
    100946: "baby_supplies",
    100947: "baby_supplies",
    100948: "baby_supplies",
    100949: "baby_supplies",
    100950: "baby_supplies",
    100951: "baby_supplies",
    100952: "baby_supplies",
    100953: "baby_supplies",
    100954: "baby_supplies",
    100955: "baby_supplies",
    100956: "baby_supplies",
    100957: "baby_supplies",
    100958: "baby_supplies",
    100959: "baby_supplies",
    100960: "baby_supplies",
    100961: "baby_supplies",
    100962: "baby_supplies",
    100963: "baby_supplies",
    100964: "baby_supplies",
    100965: "baby_supplies",
    100966: "baby_supplies",
    100967: "baby_supplies",
    100968: "baby_supplies",
    100969: "baby_supplies",
    100970: "baby_supplies",
    100971: "baby_supplies",
    100972: "baby_supplies",
    100973: "baby_supplies",
    100974: "baby_supplies",
    100975: "baby_supplies",
    100976: "baby_supplies",
    100977: "baby_supplies",
    100978: "baby_supplies",
    100979: "baby_supplies",
    100980: "baby_supplies",
    100981: "baby_supplies",
    100982: "baby_supplies",
    100983: "baby_supplies",
    100984: "baby_supplies",
    100985: "baby_supplies",
    100986: "baby_supplies",
    100987: "baby_supplies",
    100988: "baby_supplies",
    100989: "baby_supplies",
    100990: "baby_supplies",
    100991: "baby_supplies",
    100992: "baby_supplies",
    100993: "baby_supplies",
    100994: "baby_supplies",
    100995: "baby_supplies",
    100996: "baby_supplies",
    100997: "baby_supplies",
    100998: "baby_supplies",
    100999: "baby_supplies",
    101000: "baby_supplies",
    101001: "baby_supplies",
    101002: "baby_supplies",
    101003: "baby_supplies",
    101004: "baby_supplies",
    101005: "baby_supplies",
    101006: "toys",
    101007: "baby_supplies",
    101008: "toys",
    101009: "baby_supplies",
    101010: "baby_supplies",
    101011: "toys",
    101012: "toys",
    101013: "baby_supplies",
    101014: "baby_supplies",
    101015: "toys",
    101016: "baby_supplies",
    101017: "baby_supplies",
    101018: "baby_supplies",
    101019: "baby_supplies",
    101020: "baby_supplies",
    101021: "baby_supplies",
    101022: "baby_supplies",
    101023: "baby_supplies",
    101024: "baby_supplies",
    101025: "baby_supplies",
    101026: "baby_supplies",
    101027: "baby_supplies",
    101028: "baby_supplies",
    101029: "baby_supplies",
    101030: "baby_supplies",
    101031: "baby_supplies",
    101032: "baby_supplies",
    101033: "baby_supplies",
    101034: "baby_supplies",
    101035: "baby_supplies",
    101036: "baby_supplies",
    101037: "baby_supplies",
    101038: "baby_supplies",
    101039: "baby_supplies",
    101040: "baby_supplies",
    101041: "baby_supplies",
    101042: "baby_supplies",
    101043: "baby_supplies",
    101044: "baby_supplies",
    101045: "baby_supplies",
    101046: "baby_supplies",
    101047: "baby_supplies",
    101048: "baby_supplies",
    101049: "baby_supplies",
    101050: "baby_supplies",
    101051: "baby_supplies",
    101052: "baby_supplies",
    101053: "baby_supplies",
    101054: "baby_supplies",
    101055: "baby_supplies",
    101056: "baby_supplies",
    101057: "baby_supplies",
    101058: "baby_supplies",
    101059: "baby_supplies",
    101060: "baby_supplies",
    101061: "baby_supplies",
    101062: "baby_supplies",
    101063: "baby_supplies",
    101064: "baby_supplies",
    101065: "baby_supplies",
    101066: "baby_supplies",
    101067: "baby_supplies",
    101068: "baby_supplies",
    101069: "baby_supplies",
    101070: "baby_supplies",
    101071: "baby_supplies",
    101072: "baby_supplies",
    101073: "games",
    101074: "games",
    101075: "games",
    101076: "games",
    101077: "games",
    101078: "games",
    101079: "games",
    101080: "games",
    101081: "games",
    101082: "games",
    101083: "games",
    101084: "games",
    101085: "games",
    101086: "games",
    101087: "games",
    101088: "games",
    101089: "games",
    101090: "games",
    101091: "games",
    101092: "electronics_and_software",
    101093: "electronics_and_software",
    101094: "electronics_and_software",
    101095: "electronics_and_software",
    101096: "electronics_and_software",
    101097: "electronics_and_software",
    101098: "electronics_and_software",
    101099: "electronics_and_software",
    101100: "electronics_and_software",
    101101: "electronics_and_software",
    101102: "electronics_and_software",
    101103: "electronics_and_software",
    101104: "electronics_and_software",
    101105: "electronics_and_software",
    101106: "electronics_and_software",
    101107: "electronics_and_software",
    101108: "electronics_and_software",
    101109: "electronics_and_software",
    101110: "electronics_and_software",
    101111: "electronics_and_software",
    101112: "electronics_and_software",
    101113: "electronics_and_software",
    101114: "electronics_and_software",
    101115: "electronics_and_software",
    101116: "electronics_and_software",
    101117: "electronics_and_software",
    101118: "electronics_and_software",
    101119: "electronics_and_software",
    101120: "electronics_and_software",
    101121: "electronics_and_software",
    101122: "electronics_and_software",
    101123: "electronics_and_software",
    101124: "electronics_and_software",
    101125: "electronics_and_software",
    101126: "electronics_and_software",
    101127: "home_supplies",
    101128: "home_supplies",
    101129: "home_supplies",
    101130: "home_supplies",
    101131: "home_supplies",
    101132: "home_supplies",
    101133: "home_supplies",
    101134: "home_supplies",
    101135: "home_supplies",
    101136: "home_supplies",
    101137: "home_supplies",
    101138: "home_supplies",
    101139: "home_supplies",
    101140: "home_supplies",
    101141: "home_supplies",
    101142: "home_supplies",
    101143: "home_supplies",
    101144: "home_supplies",
    101145: "home_supplies",
    101146: "home_supplies",
    101147: "home_supplies",
    101148: "home_supplies",
    101149: "home_supplies",
    101150: "home_supplies",
    101151: "home_supplies",
    101152: "home_supplies",
    101153: "home_supplies",
    101154: "home_supplies",
    101155: "home_supplies",
    101156: "home_supplies",
    101157: "home_supplies",
    101158: "home_supplies",
    101159: "home_supplies",
    101160: "home_supplies",
    101161: "home_supplies",
    101162: "home_supplies",
    101163: "home_supplies",
    101164: "home_supplies",
    101165: "home_supplies",
    101166: "home_supplies",
    101167: "home_supplies",
    101168: "home_supplies",
    101169: "home_supplies",
    101170: "home_supplies",
    101171: "home_supplies",
    101172: "home_supplies",
    101173: "home_supplies",
    101174: "home_supplies",
    101175: "home_supplies",
    101176: "home_improvement",
    101177: "home_improvement",
    101178: "home_improvement",
    101179: "home_improvement",
    101180: "home_improvement",
    101181: "home_improvement",
    101182: "home_improvement",
    101183: "home_improvement",
    101184: "home_improvement",
    101185: "home_improvement",
    101186: "home_improvement",
    101187: "home_improvement",
    101188: "home_improvement",
    101189: "home_improvement",
    101190: "home_improvement",
    101191: "home_supplies",
    101192: "home_improvement",
    101193: "home_improvement",
    101194: "home_improvement",
    101195: "home_improvement",
    101196: "home_improvement",
    101197: "home_improvement",
    101198: "home_improvement",
    101199: "home_improvement",
    101200: "home_supplies",
    101201: "home_supplies",
    101202: "home_supplies",
    101203: "home_supplies",
    101204: "home_supplies",
    101205: "home_supplies",
    101206: "home_supplies",
    101207: "home_supplies",
    101208: "home_supplies",
    101209: "home_supplies",
    101210: "home_supplies",
    101211: "home_supplies",
    101212: "home_supplies",
    101213: "home_supplies",
    101214: "home_supplies",
    101215: "home_supplies",
    101216: "home_supplies",
    101217: "home_supplies",
    101218: "home_supplies",
    101219: "home_supplies",
    101220: "home_supplies",
    101221: "home_supplies",
    101222: "home_supplies",
    101223: "home_supplies",
    101224: "home_supplies",
    101225: "home_supplies",
    101226: "home_supplies",
    101227: "home_supplies",
    101228: "home_supplies",
    101229: "home_supplies",
    101230: "home_supplies",
    101231: "home_supplies",
    101232: "home_supplies",
    101233: "home_supplies",
    101234: "home_supplies",
    101235: "home_supplies",
    101236: "home_supplies",
    101237: "home_supplies",
    101238: "home_supplies",
    101239: "home_supplies",
    101240: "home_supplies",
    101241: "home_supplies",
    101242: "home_supplies",
    101243: "home_supplies",
    101244: "home_supplies",
    101245: "home_supplies",
    101246: "home_supplies",
    101247: "home_supplies",
    101248: "home_supplies",
    101249: "home_supplies",
    101250: "home_improvement",
    101251: "home_supplies",
    101252: "home_improvement",
    101253: "home_supplies",
    101254: "home_supplies",
    101255: "home_supplies",
    101256: "home_supplies",
    101257: "home_supplies",
    101258: "home_supplies",
    101259: "home_supplies",
    101260: "home_supplies",
    101261: "home_supplies",
    101262: "home_supplies",
    101263: "home_supplies",
    101264: "home_supplies",
    101265: "home_supplies",
    101266: "home_supplies",
    101267: "home_supplies",
    101268: "home_supplies",
    101269: "home_supplies",
    101270: "home_supplies",
    101271: "sports",
    101272: "sports",
    101273: "sports",
    101274: "sports",
    101275: "sports",
    101276: "sports",
    101277: "sports",
    101278: "sports",
    101279: "sports",
    101280: "sports",
    101281: "sports",
    101282: "sports",
    101283: "sports",
    101284: "sports",
    101285: "sports",
    101286: "sports",
    101287: "sports",
    101288: "sports",
    101289: "sports",
    101290: "sports",
    101291: "sports",
    101292: "sports",
    101293: "sports",
    101294: "sports",
    101295: "sporting_goods",
    101296: "sporting_goods",
    101297: "sporting_goods",
    101298: "sporting_goods",
    101299: "sporting_goods",
    101300: "sporting_goods",
    101301: "sporting_goods",
    101302: "sporting_goods",
    101303: "sporting_goods",
    101304: "sporting_goods",
    101305: "sporting_goods",
    101306: "sporting_goods",
    101307: "sporting_goods",
    101308: "sporting_goods",
    101309: "sporting_goods",
    101310: "sporting_goods",
    101311: "sporting_goods",
    101312: "sporting_goods",
    101313: "sporting_goods",
    101314: "sporting_goods",
    101315: "sporting_goods",
    101316: "sporting_goods",
    101317: "sporting_goods",
    101318: "sporting_goods",
    101319: "sporting_goods",
    101320: "sporting_goods",
    101321: "sporting_goods",
    101322: "sporting_goods",
    101323: "sporting_goods",
    101324: "sporting_goods",
    101325: "sporting_goods",
    101326: "sporting_goods",
    101327: "sporting_goods",
    101328: "sporting_goods",
    101329: "sporting_goods",
    101330: "office_supplies",
    101331: "office_supplies",
    101332: "office_supplies",
    101333: "office_supplies",
    101334: "office_supplies",
    101335: "office_supplies",
    101336: "office_supplies",
    101337: "office_supplies",
    101338: "office_supplies",
    101339: "office_supplies",
    101340: "office_supplies",
    101341: "office_supplies",
    101342: "office_supplies",
    101343: "office_supplies",
    101344: "office_supplies",
    101345: "office_supplies",
    101346: "office_supplies",
    101347: "office_supplies",
    101348: "office_supplies",
    101349: "office_supplies",
    101350: "office_supplies",
    101351: "office_supplies",
    101352: "office_supplies",
    101353: "office_supplies",
    101354: "office_supplies",
    101355: "office_supplies",
    101356: "office_supplies",
    101357: "office_supplies",
    101358: "office_supplies",
    101359: "office_supplies",
    101360: "office_supplies",
    101361: "office_supplies",
    101362: "office_supplies",
    101363: "office_supplies",
    101364: "office_supplies",
    101365: "office_supplies",
    101366: "office_supplies",
    101367: "office_supplies",
    101368: "office_supplies",
    101369: "office_supplies",
    101370: "office_supplies",
    101371: "office_supplies",
    101372: "office_supplies",
    101373: "office_supplies",
    101374: "office_supplies",
    101375: "office_supplies",
    101376: "office_supplies",
    101377: "office_supplies",
    101378: "office_supplies",
    101379: "office_supplies",
    101380: "office_supplies",
    101381: "office_supplies",
    101382: "office_supplies",
    101383: "office_supplies",
    101384: "office_supplies",
    101385: "entertainment",
    101386: "entertainment",
    101387: "entertainment",
    101388: "entertainment",
    101389: "entertainment",
    101390: "entertainment",
    101391: "entertainment",
    101392: "entertainment",
    101393: "entertainment",
    101394: "entertainment",
    101395: "entertainment",
    101396: "entertainment",
    101397: "entertainment",
    101398: "entertainment",
    101399: "entertainment",
    101400: "games",
    101401: "games",
    101402: "games",
    101403: "games",
    101404: "games",
    101405: "games",
    101406: "games",
    101407: "games",
    101408: "games",
    101409: "games",
    101410: "movies_and_music",
    101411: "movies_and_music",
    101412: "movies_and_music",
    101413: "movies_and_music",
    101414: "movies_and_music",
    101415: "shopping",
    101416: "shopping",
    101417: "shopping",
    101418: "shopping",
    101419: "shopping",
    101420: "shopping",
    101421: "shopping",
    101422: "shopping",
    101423: "shopping",
    101424: "shopping",
    101425: "electronics_and_software",
    101426: "shopping",
    101427: "shopping",
    101428: "shopping",
    101429: "shopping",
    101430: "shopping",
    101431: "shopping",
    101432: "shopping",
    101433: "shopping",
    101434: "shopping",
    101435: "shopping",
    101436: "shopping",
    101437: "shopping",
    101438: "shopping",
    101439: "shopping",
    101440: "shopping",
    101441: "shopping",
    101442: "shopping",
    101443: "service_and_parts",
    101444: "service_and_parts",
    101445: "service_and_parts",
    101446: "service_and_parts",
    101447: "service_and_parts",
    101448: "service_and_parts",
    101449: "service_and_parts",
    101450: "service_and_parts",
    101451: "service_and_parts",
    101452: "service_and_parts",
    101453: "service_and_parts",
    101454: "service_and_parts",
    101455: "electronics_and_software",
    101456: "service_and_parts",
    101457: "automotive",
    101458: "service_and_parts",
    101459: "service_and_parts",
    101460: "automotive",
    101461: "service_and_parts",
    101462: "service_and_parts",
    101463: "service_and_parts",
    101464: "service_and_parts",
    101465: "service_and_parts",
    101466: "service_and_parts",
    101467: "service_and_parts",
    101468: "service_and_parts",
    101469: "service_and_parts",
    101470: "service_and_parts",
    101471: "service_and_parts",
    101472: "automotive",
    101473: "shopping",
    101474: "shopping",
    101475: "shopping",
    101476: "shopping",
    101477: "shopping",
    101478: "shopping",
    101479: "service_and_parts",
    101480: "service_and_parts",
    101481: "shopping",
    101482: "shopping",
    101483: "shopping",
    101484: "service_and_parts",
    101485: "service_and_parts",
    101486: "service_and_parts",
    101487: "service_and_parts",
    101488: "service_and_parts",
    101489: "service_and_parts",
    101490: "service_and_parts",
    101491: "service_and_parts",
    101492: "service_and_parts",
    101493: "service_and_parts",
    101494: "service_and_parts",
    101495: "service_and_parts",
    101496: "service_and_parts",
    101497: "service_and_parts",
    101498: "entertainment",
    101499: "entertainment",
    101500: "movies_and_music",
    101501: "entertainment",
    101502: "entertainment",
    101503: "food_and_dining",
    101504: "food_and_dining",
    101505: "food_and_dining",
    101506: "shopping",
    101507: "groceries",
    101508: "shopping",
    101509: "home",
    101510: "business_services",
    101511: "insurance",
    101512: "business_services",
    101513: "amusement",
    101514: "business_services",
    101515: "wellness",
    101516: "doctor",
    101517: "sports",
    101518: "personal_care",
    101519: "wellness",
    101520: "wellness",
    101521: "phone",
    101522: "phone",
    101523: "phone",
    101524: "uncategorized",
    101525: "hotel",
    101526: "uncategorized",
    101527: "travel",
    101528: "public_transportation",
    101529: "public_transportation",
    101530: "car_rental",
    101531: "travel",
    101532: "shopping",
    101533: "shopping",
    101534: "shopping",
    101535: "shopping",
    101536: "newspapers_and_magazines",
    101537: "newspapers_and_magazines",
    101538: "newspapers_and_magazines",
    101539: "books_and_supplies",
    101540: "books_and_supplies",
    101541: "books_and_supplies",
    101542: "books_and_supplies",
    101543: "books_and_supplies",
    101544: "books_and_supplies",
    101545: "books_and_supplies",
    101546: "books_and_supplies",
    101547: "books_and_supplies",
    101548: "books_and_supplies",
    101549: "books_and_supplies",
    101550: "books_and_supplies",
    101551: "books_and_supplies",
    101552: "books_and_supplies",
    101553: "books_and_supplies",
    101554: "books_and_supplies",
    101555: "books_and_supplies",
    101556: "books_and_supplies",
    101557: "books_and_supplies",
    101558: "books_and_supplies",
    101559: "books_and_supplies",
    101560: "books_and_supplies",
    101561: "books_and_supplies",
    101562: "books_and_supplies",
    101563: "books_and_supplies",
    101564: "books_and_supplies",
    101565: "books_and_supplies",
    101566: "books_and_supplies",
    101567: "books_and_supplies",
    101568: "books_and_supplies",
    101569: "books_and_supplies",
    101570: "books_and_supplies",
    101571: "books_and_supplies",
    101572: "books_and_supplies",
    101573: "books_and_supplies",
    101574: "groceries",
    101575: "groceries",
    101576: "groceries",
    101577: "groceries",
    101578: "groceries",
    101579: "groceries",
    101580: "groceries",
    101581: "groceries",
    101582: "groceries",
    101583: "groceries",
    101584: "groceries",
    101585: "groceries",
    101586: "groceries",
    101587: "groceries",
    101588: "groceries",
    101589: "groceries",
    101590: "groceries",
    101591: "groceries",
    101592: "groceries",
    101593: "groceries",
    101594: "groceries",
    101595: "groceries",
    101596: "groceries",
    101597: "groceries",
    101598: "groceries",
    101599: "groceries",
    101600: "groceries",
    101601: "groceries",
    101602: "groceries",
    101603: "groceries",
    101604: "groceries",
    101605: "groceries",
    101606: "groceries",
    101607: "personal_care",
    101608: "personal_care",
    101609: "personal_care",
    101610: "personal_care",
    101611: "personal_care",
    101612: "personal_care",
    101613: "personal_care",
    101614: "personal_care",
    101615: "personal_care",
    101616: "personal_care",
    101617: "personal_care",
    101618: "personal_care",
    101619: "personal_care",
    101620: "personal_care",
    101621: "personal_care",
    101622: "personal_care",
    101623: "personal_care",
    101624: "personal_care",
    101625: "personal_care",
    101626: "personal_care",
    101627: "personal_care",
    101628: "personal_care",
    101629: "personal_care",
    101630: "personal_care",
    101631: "personal_care",
    101632: "personal_care",
    101633: "personal_care",
    101634: "personal_care",
    101635: "personal_care",
    101636: "personal_care",
    101637: "personal_care",
    101638: "personal_care",
    101639: "personal_care",
    101640: "personal_care",
    101641: "personal_care",
    101642: "personal_care",
    101643: "personal_care",
    101644: "personal_care",
    101645: "personal_care",
    101646: "personal_care",
    101647: "personal_care",
    101648: "personal_care",
    101649: "personal_care",
    101650: "personal_care",
    101651: "personal_care",
    101652: "personal_care",
    101653: "personal_care",
    101654: "personal_care",
    101655: "personal_care",
    101656: "personal_care",
    101657: "personal_care",
    101658: "personal_care",
    101659: "personal_care",
    101660: "personal_care",
    101661: "personal_care",
    101662: "personal_care",
    101663: "personal_care",
    101664: "personal_care",
    101665: "personal_care",
    101666: "personal_care",
    101667: "personal_care",
    101668: "personal_care",
    101669: "personal_care",
    101670: "personal_care",
    101671: "personal_care",
    101672: "personal_care",
    101673: "personal_care",
    101674: "personal_care",
    101675: "personal_care",
    101676: "personal_care",
    101677: "pet_food_and_supplies",
    101678: "pet_food_and_supplies",
    101679: "pet_food_and_supplies",
    101680: "pet_food_and_supplies",
    101681: "pet_food_and_supplies",
    101682: "pet_food_and_supplies",
    101683: "pet_food_and_supplies",
    101684: "pet_food_and_supplies",
    101685: "pet_food_and_supplies",
    101686: "pet_food_and_supplies",
    101687: "pet_grooming",
    101688: "pet_grooming",
    101689: "pet_grooming",
    101690: "pet_grooming",
    101691: "baby_supplies",
    101692: "baby_supplies",
    101693: "baby_supplies",
    101694: "baby_supplies",
    101695: "baby_supplies",
    101696: "baby_supplies",
    101697: "baby_supplies",
    101698: "baby_supplies",
    101699: "baby_supplies",
    101700: "baby_supplies",
    101701: "baby_supplies",
    101702: "baby_supplies",
    101703: "baby_supplies",
    101704: "baby_supplies",
    101705: "baby_supplies",
    101706: "baby_supplies",
    101707: "baby_supplies",
    101708: "baby_supplies",
    101709: "baby_supplies",
    101710: "baby_supplies",
    101711: "baby_supplies",
    101712: "baby_supplies",
    101713: "baby_supplies",
    101714: "toys",
    101715: "toys",
    101716: "toys",
    101717: "toys",
    101718: "toys",
    101719: "toys",
    101720: "toys",
    101721: "toys",
    101722: "toys",
    101723: "toys",
    101724: "toys",
    101725: "toys",
    101726: "toys",
    101727: "toys",
    101728: "toys",
    101729: "toys",
    101730: "toys",
    101731: "toys",
    101732: "toys",
    101733: "toys",
    101734: "toys",
    101735: "toys",
    101736: "toys",
    101737: "toys",
    101738: "toys",
    101739: "toys",
    101740: "baby_supplies",
    101741: "baby_supplies",
    101742: "baby_supplies",
    101743: "baby_supplies",
    101744: "baby_supplies",
    101745: "baby_supplies",
    101746: "baby_supplies",
    101747: "baby_supplies",
    101748: "baby_supplies",
    101749: "baby_supplies",
    101750: "baby_supplies",
    101751: "baby_supplies",
    101752: "baby_supplies",
    101753: "baby_supplies",
    101754: "baby_supplies",
    101755: "baby_supplies",
    101756: "baby_supplies",
    101757: "baby_supplies",
    101758: "baby_supplies",
    101759: "baby_supplies",
    101760: "baby_supplies",
    101761: "baby_supplies",
    101762: "baby_supplies",
    101763: "baby_supplies",
    101764: "baby_supplies",
    101765: "baby_supplies",
    101766: "baby_supplies",
    101767: "baby_supplies",
    101768: "baby_supplies",
    101769: "baby_supplies",
    101770: "baby_supplies",
    101771: "baby_supplies",
    101772: "baby_supplies",
    101773: "baby_supplies",
    101774: "baby_supplies",
    101775: "baby_supplies",
    101776: "baby_supplies",
    101777: "baby_supplies",
    101778: "baby_supplies",
    101779: "baby_supplies",
    101780: "baby_supplies",
    101781: "baby_supplies",
    101782: "baby_supplies",
    101783: "baby_supplies",
    101784: "baby_supplies",
    101785: "baby_supplies",
    101786: "baby_supplies",
    101787: "baby_supplies",
    101788: "baby_supplies",
    101789: "baby_supplies",
    101790: "home_improvement",
    101791: "home_improvement",
    101792: "home_improvement",
    101793: "home_improvement",
    101794: "home_improvement",
    101795: "home_improvement",
    101796: "home_improvement",
    101797: "home_improvement",
    101798: "home_improvement",
    101799: "home_improvement",
    101800: "home_improvement",
    101801: "home_improvement",
    101802: "home_improvement",
    101803: "home_improvement",
    101804: "home_improvement",
    101805: "home_improvement",
    101806: "home_improvement",
    101807: "home_improvement",
    101808: "home_improvement",
    101809: "home_improvement",
    101810: "home_improvement",
    101811: "home_improvement",
    101812: "home_supplies",
    101813: "home_supplies",
    101814: "home_supplies",
    101815: "home_supplies",
    101816: "sporting_goods",
    101817: "sporting_goods",
    101818: "sporting_goods",
    101819: "sporting_goods",
    101820: "sporting_goods",
    101821: "sporting_goods",
    101822: "sporting_goods",
    101823: "sporting_goods",
    101824: "sporting_goods",
    101825: "sporting_goods",
    101826: "sporting_goods",
    101827: "sporting_goods",
    101828: "sporting_goods",
    101829: "sporting_goods",
    101830: "sporting_goods",
    101831: "sporting_goods",
    101832: "sporting_goods",
    101833: "sporting_goods",
    101834: "sporting_goods",
    101835: "sporting_goods",
    101836: "sporting_goods",
    101837: "sporting_goods",
    101838: "sporting_goods",
    101839: "sporting_goods",
    101840: "sporting_goods",
    101841: "sporting_goods",
    101842: "sporting_goods",
    101843: "sporting_goods",
    101844: "sporting_goods",
    101845: "sporting_goods",
    101846: "sporting_goods",
    101847: "sporting_goods",
    101848: "sporting_goods",
    101849: "sporting_goods",
    101850: "sporting_goods",
    101851: "sporting_goods",
    101852: "sporting_goods",
    101853: "sporting_goods",
    101854: "sporting_goods",
    101855: "sporting_goods",
    101856: "sporting_goods",
    101857: "sporting_goods",
    101858: "sporting_goods",
    101859: "sporting_goods",
    101860: "sporting_goods",
    101861: "sporting_goods",
    101862: "sporting_goods",
    101863: "sporting_goods",
    101864: "sporting_goods",
    101865: "sporting_goods",
    101866: "sporting_goods",
    101867: "sporting_goods",
    101868: "sporting_goods",
    101869: "sporting_goods",
    101870: "sporting_goods",
    101871: "sporting_goods",
    101872: "sporting_goods",
    101873: "sporting_goods",
    101874: "sporting_goods",
    101875: "sporting_goods",
    101876: "sporting_goods",
    101877: "sporting_goods",
    101878: "sporting_goods",
    101879: "sporting_goods",
    101880: "sporting_goods",
    101881: "sporting_goods",
    101882: "sporting_goods",
    101883: "sporting_goods",
    101884: "sporting_goods",
    101885: "sporting_goods",
    101886: "sporting_goods",
    101887: "sporting_goods",
    101888: "sporting_goods",
    101889: "sporting_goods",
    101890: "sporting_goods",
    101891: "sporting_goods",
    101892: "sporting_goods",
    101893: "sporting_goods",
    101894: "sporting_goods",
    101895: "sporting_goods",
    101896: "sporting_goods",
    101897: "sporting_goods",
    101898: "sporting_goods",
    101899: "sporting_goods",
    101900: "sporting_goods",
    101901: "sporting_goods",
    101902: "service_and_parts",
    101903: "service_and_parts",
    101904: "service_and_parts",
    101905: "service_and_parts",
    101906: "service_and_parts",
    101907: "service_and_parts",
    101908: "service_and_parts",
    101909: "service_and_parts",
    101910: "service_and_parts",
    101911: "service_and_parts",
    101912: "service_and_parts",
    101913: "service_and_parts",
    101914: "service_and_parts",
    101915: "service_and_parts",
    101916: "service_and_parts",
    101917: "service_and_parts",
    101918: "service_and_parts",
    101919: "service_and_parts",
    101920: "service_and_parts",
    101921: "service_and_parts",
    101922: "service_and_parts",
    101923: "service_and_parts",
    101924: "service_and_parts",
    101925: "pet_food_and_supplies",
    101926: "pet_food_and_supplies",
    101927: "pet_food_and_supplies",
    101928: "pet_food_and_supplies",
    101929: "service_and_parts",
    101930: "service_and_parts",
    101931: "service_and_parts",
    101932: "electronics_and_software",
    101933: "electronics_and_software",
    101934: "electronics_and_software",
    101935: "electronics_and_software",
    101936: "electronics_and_software",
    101937: "electronics_and_software",
    101938: "electronics_and_software",
    101939: "electronics_and_software",
    101940: "electronics_and_software",
    101941: "electronics_and_software",
    101942: "electronics_and_software",
    101943: "electronics_and_software",
    101944: "electronics_and_software",
    101945: "electronics_and_software",
    101946: "electronics_and_software",
    101947: "electronics_and_software",
    101948: "electronics_and_software",
    101949: "electronics_and_software",
    101950: "electronics_and_software",
    101951: "electronics_and_software",
    101952: "electronics_and_software",
    101953: "electronics_and_software",
    101954: "electronics_and_software",
    101955: "electronics_and_software",
    101956: "electronics_and_software",
    101957: "electronics_and_software",
    101958: "electronics_and_software",
    101959: "electronics_and_software",
    101960: "electronics_and_software",
    101961: "electronics_and_software",
    101962: "electronics_and_software",
    101963: "electronics_and_software",
    101964: "electronics_and_software",
    101965: "electronics_and_software",
    101966: "electronics_and_software",
    101967: "electronics_and_software",
    101968: "electronics_and_software",
    101969: "electronics_and_software",
    101970: "electronics_and_software",
    101971: "electronics_and_software",
    101972: "electronics_and_software",
    101973: "electronics_and_software",
    101974: "electronics_and_software",
    101975: "electronics_and_software",
    101976: "electronics_and_software",
    101977: "electronics_and_software",
    101978: "electronics_and_software",
    101979: "electronics_and_software",
    101980: "electronics_and_software",
    101981: "electronics_and_software",
    101982: "electronics_and_software",
    101983: "electronics_and_software",
    101984: "electronics_and_software",
    101985: "electronics_and_software",
    101986: "electronics_and_software",
    101987: "electronics_and_software",
    101988: "electronics_and_software",
    101989: "electronics_and_software",
    101990: "electronics_and_software",
    101991: "electronics_and_software",
    101992: "electronics_and_software",
    101993: "electronics_and_software",
    101994: "electronics_and_software",
    101995: "electronics_and_software",
    101996: "electronics_and_software",
    101997: "electronics_and_software",
    101998: "electronics_and_software",
    101999: "electronics_and_software",
    102000: "electronics_and_software",
    102001: "electronics_and_software",
    102002: "personal_care",
    102003: "personal_care",
    102004: "personal_care",
    102005: "personal_care",
    102006: "personal_care",
    102007: "personal_care",
    102008: "personal_care",
    102009: "personal_care",
    102010: "personal_care",
    102011: "personal_care",
    102012: "personal_care",
    102013: "personal_care",
    102014: "personal_care",
    102015: "personal_care",
    102016: "personal_care",
    102017: "movies_and_music",
    102018: "movies_and_music",
    102019: "movies_and_music",
    102020: "movies_and_music",
    102021: "electronics_and_software",
    102022: "electronics_and_software",
    102023: "electronics_and_software",
    102024: "groceries",
    102025: "groceries",
    102026: "groceries",
    102027: "groceries",
    102028: "groceries",
    102029: "personal_care",
    102030: "personal_care",
    102031: "personal_care",
    102032: "personal_care",
    102033: "personal_care",
    102034: "personal_care",
    102035: "personal_care",
    102036: "uncategorized",
    102042: "clothing",
    102043: "clothing",
    102044: "clothing",
    102045: "clothing",
    102046: "clothing",
    102047: "wellness",
    102051: "uncategorized",
    102052: "uncategorized",
    102053: "uncategorized",
    102054: "shopping",
    102055: "shopping",
    102056: "shopping",
    102057: "shopping",
    102058: "personal_care",
    102059: "service_and_parts",
    102060: "shopping",
    102061: "shopping",
    102062: "shopping",
    102063: "shopping",
    102064: "shopping",
    102065: "shopping",
    102066: "shopping",
    102067: "shopping",
    102068: "shopping",
    102069: "shopping",
    102070: "baby_supplies",
    102071: "groceries",
    102072: "groceries",
    102073: "groceries",
    102074: "groceries",
    102075: "groceries",
    102076: "groceries",
    102077: "groceries",
    102078: "groceries",
    102079: "groceries",
    102080: "groceries",
    102081: "groceries",
    102082: "groceries",
    102083: "groceries",
    102084: "groceries",
    102085: "groceries",
    102086: "groceries",
    102087: "groceries",
    102088: "groceries",
    102089: "groceries",
    102090: "groceries",
    102091: "groceries",
    102092: "groceries",
    102093: "groceries",
    102094: "shopping",
    102095: "phone",
    102096: "phone",
    102097: "phone",
    102098: "uncategorized",
    102099: "uncategorized",
    102100: "insurance",
    102101: "utilities",
    102102: "utilities",
    102103: "utilities",
    102104: "utilities",
    102105: "utilities",
    102106: "utilities",
    102107: "utilities",
    102108: "fees_and_charges",
    102109: "fees_and_charges",
    102110: "fees_and_charges",
    102111: "fees_and_charges",
    102112: "fees_and_charges",
    102113: "fees_and_charges",
    102114: "fees_and_charges",
    102115: "fees_and_charges",
    102116: "fees_and_charges",
    102117: "fees_and_charges",
    102118: "fees_and_charges",
    102119: "fees_and_charges",
    102120: "fees_and_charges",
    102121: "fees_and_charges",
    102122: "fees_and_charges",
    102123: "fees_and_charges",
    102124: "fees_and_charges",
    102125: "fees_and_charges",
    102126: "fees_and_charges",
    102127: "fees_and_charges",
    102128: "fees_and_charges",
    102129: "fees_and_charges",
    102130: "fees_and_charges",
    102131: "fees_and_charges",
    102132: "fees_and_charges",
    102133: "fees_and_charges",
    102134: "fees_and_charges",
    102135: "fees_and_charges",
    102136: "fees_and_charges",
    102137: "fees_and_charges",
    102138: "fees_and_charges",
    102139: "fees_and_charges",
    102140: "fees_and_charges",
    102141: "fees_and_charges",
    102142: "fees_and_charges",
    102143: "television",
    102144: "movies_and_music",
    102145: "fees_and_charges",
    102146: "fees_and_charges",
    102147: "fees_and_charges",
    102148: "travel",
    102149: "travel",
    102150: "public_transportation",
    102151: "hotel",
    102152: "taxi",
    102153: "gifts_and_donations",
    102154: "gifts_and_donations",
    102155: "uncategorized",
    102156: "uncategorized",
    102157: "uncategorized",
    102158: "uncategorized",
    102159: "uncategorized",
    102160: "entertainment",
    102161: "movies_and_music",
    102162: "uncategorized",
    102163: "doctor",
    102164: "health_and_fitness",
    102165: "home",
    102166: "uncategorized",
    102167: "uncategorized",
    102168: "uncategorized",
    102169: "uncategorized",
    102170: "uncategorized",
    102171: "clothing",
    102172: "sporting_goods",
    102173: "sporting_goods",
    102174: "sporting_goods",
    102175: "sporting_goods"
};