const allAggregatorValues = [
    { value: 'saltedge', label: 'Saltedge' },
    { value: 'finverse', label: 'Finverse' },
    { value: '', label: 'Disabled' }
];

const saltedgeAggregatorValues = [
    { value: 'saltedge', label: 'Saltedge' },
    { value: '', label: 'Disabled' }
];

const finverseAggregatorValues = [
    { value: 'finverse', label: 'Finverse' },
    { value: '', label: 'Disabled' }
];

export const data = {
    american_express_sg: saltedgeAggregatorValues, 
    bank_of_china_sg: saltedgeAggregatorValues, 
    cimb_bank_sg: saltedgeAggregatorValues, 
    citi_sg: finverseAggregatorValues,
    dbs_sg: allAggregatorValues, 
    fakebank_interactive_xf: saltedgeAggregatorValues, 
    fakebank_semi_interactive_xf: saltedgeAggregatorValues, 
    fakebank_two_step_interactive_xf: saltedgeAggregatorValues, 
    fakebank_with_crypto_assets_xf: saltedgeAggregatorValues, 
    fakebank_with_natures_xf: saltedgeAggregatorValues, 
    finverse_test_bank: allAggregatorValues, 
    hsbc_sg: saltedgeAggregatorValues, 
    ocbc_sg: allAggregatorValues, 
    scb_sg: saltedgeAggregatorValues, 
    uob_sg: allAggregatorValues
};

