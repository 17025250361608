export const columns = [
    {
        selector: row => row.id,
        name: "ID",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.loan_provider_id,
        name: "Loan Provider ID",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.name,
        name: "Name",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.featured_position,
        name: "Featured Position",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.currency,
        name: "Currency",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.created_datetime,
        name: "Created Datetime",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.updated_datetime,
        name: "Updated Datetime",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
];