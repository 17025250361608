export const columns = [
    {
        selector: row => row.id,
        name: "ID",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.display_name,
        name: "Display Name",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.dobin_category,
        name: "Dobin Category",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.merchant_names,
        format: row => row.merchant_names && row.merchant_names.join(", "),
        name: "Merchant Names",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.mccs,
        format: row => row.mccs && row.mccs.join(", "),
        name: "MCCs",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
];