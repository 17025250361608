export const columns = [
    {
        selector: row => row.id,
        name: "ID",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.source,
        name: "Source",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.aggregator,
        name: "Aggregator",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.source_id,
        name: "Source ID",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.voucher_type,
        name: "Voucher Type",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.categories,
        format: row => row.categories.join(", "),
        name: "Categories",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.title,
        name: "Title",
        maxWidth: "300px",
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.description,
        name: "Description",
        maxWidth: "300px",
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.other_restrictions,
        name: "Restrictions",
        maxWidth: "300px",
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.other_benefits,
        name: "Benefits",
        maxWidth: "300px",
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.merchant_name,
        name: "Merchant Name",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.merchant_phone_number,
        name: "Merchant Phone Number",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.merchant_logo_url,
        name: "Merchant Logo URL",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.website_url,
        name: "Website URL",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.redeem_url,
        name: "Redeem URL",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.coupon_url,
        name: "Coupon URL",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.coupon_image_url,
        name: "Coupon Image URL",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.expiry_date,
        name: "Expiry Date",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.validity_start_date,
        name: "Validity Start Date",
        maxWidth: '150px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.currency,
        name: "Currency",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.applicable_users,
        name: "Applicable Users",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.eligible_banks,
        name: "Eligible Banks",
        maxWidth: "300px",
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.eligible_cards,
        name: "Eligible Cards",
        maxWidth: "300px",
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.amount,
        name: "Amount",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.dobin_amount,
        name: "Dobin Amount",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.percentage,
        name: "Percentage",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.dobin_percentage,
        name: "Dobin Percentage",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.additional_amount,
        name: "Additional Amount",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.additional_percentage,
        name: "Additional Percentage",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.min_amount,
        name: "Min Amount",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.min_percentage,
        name: "Min Percentage",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.max_amount,
        name: "Max Amount",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.max_percentage,
        name: "Max Percentage",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.amount_multiplier,
        name: "Amount Multiplier",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.percentage_multiplier,
        name: "Percentage Multiplier",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.min_spend,
        name: "Min Spend",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.min_spend_text,
        name: "Min Spend Text",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.max_spend,
        name: "Max Spend",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.multi_buy,
        name: "Multi Buy",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.multi_get,
        name: "Multi Get",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.has_code,
        name: "Has Code",
        maxWidth: '150px',
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.promo_code,
        name: "Promo Code",
        maxWidth: '150px',
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.max_redeem_count,
        name: "Max Redeem Count",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.created_datetime,
        name: "Created Datetime",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.updated_datetime,
        name: "Updated Datetime",
        maxWidth: '150px',
        wrap: true,
        omit: true,
        sortable: true,
        reorder: true
    },
];