import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import FaqAction from "./action";
import callApi from "../callApi";
import "./style.css";


const FaqTableView = () => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([]);
    const [categoryBasedFaqs, setCategoryBasedFaqs] = useState({});
    const [faqCategories, setFaqCategories] = useState([]);
    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [faqs, setFaqs] = useState([]);
    const [faqCategory, setFaqCategory] = useState({});
    const [faq, setFaq] = useState({});

    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        getFaqsData();
    }, [])

    useEffect(() => {
        setFaqs(categoryBasedFaqs[faqCategory.value]);
    }, [faqCategory]);

    const getFaqsData = () => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/core/faqs`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.faqs) {
                    let categoryBasedFaqs = {};
                    for (let faq of json.data.faqs) {
                        if (!(faq.category in categoryBasedFaqs)) {
                            categoryBasedFaqs[faq.category] = [
                                faq
                            ]
                        }
                        else {
                            categoryBasedFaqs[faq.category].push(faq)
                        }
                    }
                    let faqCategories = []
                    for (let category in categoryBasedFaqs) {
                        faqCategories.push({
                            'value': category,
                            'label': category
                        })
                        categoryBasedFaqs[category] = categoryBasedFaqs[category].sort(
                            (a, b) => (a.rank - b.rank)
                        )
                    }
                    setAllData(json.data.faqs);
                    setCategoryBasedFaqs(categoryBasedFaqs);
                    setFaqCategories(faqCategories);
                    setFaqs(categoryBasedFaqs[faqCategory.value]);
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const deleteFaq = () => {
        setButtonDisabled(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/core/faqs`;
        url = url + `?document_ids=${faq.id}`
        callApi(url, 'DELETE')
            .then((json) => {
                setButtonDisabled(false);
                handleModalClose();
                getFaqsData();
            })
            .catch((err) => err);
    };

    const onAddClick = () => {
        navigate('/faqs/add');
    }

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: grid,
        margin: `0 0 ${grid * 2}px 0`,

        background: isDragging ? "#34d0b6" : "#ffffff",

        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#f0f0f0": "#f0f0f0",
        padding: grid,
        width: "auto"
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let startIndex = result.source.index;
        let endIndex = result.destination.index;
        let updatedFaqs = _.cloneDeep(faqs);
        let [removed_faq] = updatedFaqs.splice(startIndex, 1);
        updatedFaqs.splice(endIndex, 0, removed_faq);
        for (let index in updatedFaqs) {
            updatedFaqs[index]['rank'] = parseInt(index) + 1;
        }

        let updatedCategoryBasedFaqs = _.cloneDeep(categoryBasedFaqs);
        updatedCategoryBasedFaqs[faqCategory.value] = updatedFaqs;

        setFaqs(updatedFaqs);
        setCategoryBasedFaqs(updatedCategoryBasedFaqs);
    }

    const updateRanksOfFaqs = () => {
        setButtonDisabled(true);
        let ranksOfFaqs = {}
        for (let faqCategory in categoryBasedFaqs) {
            for (let faq of categoryBasedFaqs[faqCategory]) {
                ranksOfFaqs[faq['id']] = faq['rank']
            }
        }

        let url = `${process.env.REACT_APP_API_URL}/v1/core/faqs/ranks`;
        callApi(url, 'PUT', {'faqs': ranksOfFaqs})
            .then((json) => {
                setButtonDisabled(false);
                getFaqsData();
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <h1 className="title">
                FAQs
            </h1>
            <div className="row mt-40">
                <label className="col-md-4 fs-5">
                    Search by Category:
                </label>
                <Select
                    className="col-md-6"
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    name="faqCategories"
                    options={faqCategories}
                    defaultValue={
                        faqCategories[0]
                    }
                    onChange={(selected) => setFaqCategory(selected)} />
            </div>
            <div className="index-container">
                <button 
                    className="index-button"
                    onClick={onAddClick}>
                        Add
                </button>
            </div>
            <div className="mt-40">
                {
                    faqs ? 
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                {
                                    faqs.map((faq, index) => (
                                        <Draggable key={faq.id} draggableId={faq.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className="card text-start"
                                                    onClick={() => {
                                                        setFaq(_.cloneDeep(faq));
                                                        handleModalShow();
                                                    }}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}>
                                                        <div className="card-header">
                                                            <b>{faq.rank}</b>. {faq.question}
                                                            {
                                                                faq.top_query ? 
                                                                <span class="mx-2 badge text-bg-primary">
                                                                    Top Query
                                                                </span> : 
                                                                null
                                                            }
                                                        </div>
                                                        <div className="card-body">
                                                            <p className="card-text">
                                                                {faq.response}
                                                            </p>
                                                        </div>
                                                        <div className="card-footer text-body-secondary text-start">
                                                            <div>
                                                                <b>Tags: </b>
                                                                {faq.tags.join(", ")}
                                                            </div>
                                                            <div>
                                                                <b>Countries: </b>
                                                                {faq.countries.join(", ")}
                                                            </div>
                                                        </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext> : null
                }
            </div>
            <div className="index-container">
                {
                    faqs ?
                    <button 
                        className="index-button"
                        disabled={buttonDisabled}
                        onClick={updateRanksOfFaqs}>
                            Save
                    </button> : null
                }
            </div>
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Selected FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FaqAction
                        faq={faq}
                        handleModalClose={handleModalClose}
                        setButtonDisabled={setButtonDisabled}
                        updateFaqTable={() => {
                            getFaqsData()
                        }} />
                </Modal.Body>
                <Modal.Footer>
                {
                    <div>
                        <button
                            type="button"
                            className="red-button"
                            disabled={buttonDisabled}
                            onClick={() => deleteFaq()}>
                                Delete
                        </button>
                        <button 
                            form="faq-edit"
                            type="submit"
                            className="blue-button"
                            disabled={buttonDisabled}>
                                Save
                        </button>
                    </div>
                }   
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FaqTableView;