export const columns = [
    {
        selector: row => row.id,
        name: "ID",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.from_date,
        name: "From Date",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.to_date,
        name: "To Date",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        selector: row => row.use_impact.toString(),
        name: "Use Impact",
        maxWidth: '200px',
        wrap: true,
        sortable: true,
        reorder: true
    }
];